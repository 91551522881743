import { BriefcaseIcon, FolderIcon } from "@heroicons/react/24/outline";
import { DbtCloudIcon, LookerColorlessIcon, TableauIcon, FivetranIcon, ThoughtspotIcon } from "src/assets/images/icons/DelphiIcons";
import { TextWithEllipsisAndTooltip } from "src/components/TextWithEllipsisAndTooltip";
import { IExpandedNode, nodeNameMap, NodeType } from "src/features/models/discover/INode";
import { NodeIcon } from "src/features/models/discover/NodeIcon";
import { ResourceSidepaneButtons } from "src/features/models/discover/resourceSidepane/header/ResourceSidepaneButtons";
import { ResourceSidepaneChips } from "src/features/models/discover/resourceSidepane/header/ResourceSidepaneChips";

type ResourceSidepaneStickySectionProps = {
    resource: IExpandedNode;
    setResourceId: (id: string) => void;
}

export const ResourceSidepaneStickySection = ({ resource, setResourceId }: ResourceSidepaneStickySectionProps) => {
    const isSubtitleClickable = resource.parentUri && ![NodeType.TableauWorkbook, NodeType.TableauPublishedDataSource, NodeType.LookerDashboard].includes(resource.type);

    const onClickSubtitle = () => {
        setResourceId(resource.parentUri || '');
    };

    return (
        <div className="flex gap-2 p-4 border-b border-border  flex-none">
            <div className="rounded-lg border border-border p-4 w-fit h-fit">
                <NodeIcon type={resource.type} iconSize={30} databaseTechnology={resource.databaseTechnology} />
            </div>
            <div className="flex flex-col flex-1">
                <div className="flex justify-between">
                    <div className="flex-1">
                        <div className="text-lg text-text-primary break-all">
                            {resource.name}
                        </div>
                        <div onClick={() => !!isSubtitleClickable && onClickSubtitle()} className={`text-tertiary text-sm flex items-center gap-1 ${!!isSubtitleClickable && 'hover:text-slate-500 cursor-pointer'}`}>
                            <NodeSubtitle resource={resource} />
                        </div>
                        <div>
                            <ResourceSidepaneChips resource={resource} />
                        </div>
                    </div>
                    <div>
                        <ResourceSidepaneButtons resource={resource} />
                    </div>
                </div>
            </div>
        </div>
    );
};

const NodeSubtitle = ({ resource }: { resource: IExpandedNode }) => {
    const resourceName = nodeNameMap.get(resource.type);
    switch (resource.type) {
        case NodeType.DataModel:
        case NodeType.Metric:
        case NodeType.DataSource:
        case NodeType.DbtSeed:
            return <>{resourceName} in <div className="bg-slate-100 flex gap-1 items-center"><BriefcaseIcon width={16} height={16} /> <TextWithEllipsisAndTooltip maxChars={20} text={resource.dbtProjectName || ''} /> (dbt project)</div></>;
        case NodeType.LookerDashboard:
        case NodeType.LookerLook:
            return <>{resourceName} in <div className="bg-slate-100 flex gap-1 items-center"><FolderIcon width={16} height={16} /><TextWithEllipsisAndTooltip maxChars={20} text={resource.lookerFolder || ''} /> (Looker folder)</div></>;
        case NodeType.LookerExplore:
        case NodeType.LookerView:
        case NodeType.LookerNativeDerivedView:
        case NodeType.LookerSQLDerivedView:
            return <>{resourceName} in <div className="bg-slate-100 flex gap-1 items-center"><BriefcaseIcon width={16} height={16} /> <TextWithEllipsisAndTooltip maxChars={20} text={resource.lookerProject || ''} /> (Looker project)</div></>;
        case NodeType.LookerTile:
            return <>{resourceName} in <div className="bg-slate-100 flex gap-1 items-center"><LookerColorlessIcon width={16} height={16} fill="#64748B" /> <TextWithEllipsisAndTooltip maxChars={20} text={resource.parentName || ''} /> (Looker dashboard)</div></>;
        case NodeType.DbtColumn:
        case NodeType.DbtDimension:
        case NodeType.DbtMeasure:
            return <>{resourceName} in <div className="bg-slate-100 flex gap-1 items-center"><DbtCloudIcon color="#FF694A" width={12} height={12} /><TextWithEllipsisAndTooltip maxChars={30} text={resource.parentName || ''} /></div></>;
        case NodeType.LookerDimension:
        case NodeType.LookerMeasure:
            return <>{resourceName} in <div className="bg-slate-100 flex gap-1 items-center"><LookerColorlessIcon width={12} height={12} fill="#64748B" /><TextWithEllipsisAndTooltip maxChars={30} text={resource.parentName || ''} /></div></>;
        case NodeType.TableauDimension:
        case NodeType.TableauMeasure:
        case NodeType.TableauStory:
        case NodeType.TableauView:
        case NodeType.TableauDashboard:
        case NodeType.TableauEmbeddedDataSource:
            return <>{resourceName} in <div className="bg-slate-100 flex gap-1 items-center"><TableauIcon width={16} height={16} /><TextWithEllipsisAndTooltip maxChars={30} text={resource.parentName || ''} /></div></>;
        case NodeType.Table:
            if (resource.databaseTechnology) {
                return <>{resourceName} of type {resource.databaseTechnology}</>;
            }
            return <>{resourceName}</>;
        case NodeType.FivetranConnector:
        case NodeType.FivetranDestination:
            return <>{resourceName} in <div className="bg-slate-100 flex gap-1 items-center"><FivetranIcon width={16} height={16} /><TextWithEllipsisAndTooltip maxChars={30} text={resource.parentName || ''} /></div></>;
        case NodeType.TableauFlow:
            return <>{resourceName} in <div className="bg-slate-100 flex gap-1 items-center"><TableauIcon width={16} height={16} /><TextWithEllipsisAndTooltip maxChars={30} text={resource.parentName || ''} /></div></>;
        case NodeType.ThoughtspotLiveboard:
        case NodeType.ThoughtspotAnswer:
        case NodeType.ThoughtspotWorksheet:
        case NodeType.ThoughtspotTable:
        case NodeType.ThoughtspotModel:
        case NodeType.ThoughtspotFormula:
        case NodeType.ThoughtspotView:
            return <>{resourceName} in <div className="bg-slate-100 flex gap-1 items-center">
                <ThoughtspotIcon width={16} height={16} />
                <TextWithEllipsisAndTooltip maxChars={30} text={resource.parentName || ''} />
            </div></>;
        default:
            return <>{resourceName} in <div className="bg-slate-100 flex gap-1 items-center"><BriefcaseIcon width={16} height={16} /> <TextWithEllipsisAndTooltip maxChars={30} text={resource.parentName || ''} /></div></>;
    }
};
