import { useState } from 'react';
import Input from 'src/components/form/Input';
import Button from 'src/components/button/Button';
import { ButtonTypes } from 'src/components/button/types';
import { PlusIcon, XMarkIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { EQLInput } from 'src/components/EQLInput';

type MultiSelectOption = {
    value: string;
    eql: string;
};

type MultiSelectOptionsEditorProps = {
    options: MultiSelectOption[];
    onChange: (options: MultiSelectOption[]) => void;
};

const MultiSelectOptionsEditor = ({ options, onChange }: MultiSelectOptionsEditorProps) => {
    const [newValue, setNewValue] = useState('');
    const [newEql, setNewEql] = useState('');

    const handleAddOption = () => {
        if (!newValue.trim() || !newEql.trim() || options.some(opt => opt.value === newValue.trim())) return;
        
        onChange([...options, { value: newValue.trim(), eql: newEql.trim() }]);
        setNewValue('');
        setNewEql('');
    };

    const handleRemoveOption = (index: number) => {
        onChange(options.filter((_, i) => i !== index));
    };

    const handleMoveOption = (index: number, direction: 'up' | 'down') => {
        const newIndex = direction === 'up' ? index - 1 : index + 1;
        if (newIndex < 0 || newIndex >= options.length) return;

        const newOptions = [...options];
        [newOptions[index], newOptions[newIndex]] = [newOptions[newIndex], newOptions[index]];
        onChange(newOptions);
    };

    return (
        <div className="border-t border-slate-200 pt-6 mt-6">
            <div className="text-sm font-medium text-slate-700 mb-4">Multi-Select Options Configuration</div>
            <div className="flex flex-col gap-4">
                {/* Add new option form */}
                <div className="flex flex-col gap-4 p-4 border border-slate-200 rounded-lg">
                    <div className="text-sm font-medium text-slate-600">Add New Option</div>
                    <div className="flex items-start gap-4">
                        <div className="w-1/3">
                            <Input
                                value={newValue}
                                onInputChange={(e) => setNewValue(e.target.value)}
                                placeholder="Enter option value"
                            />
                        </div>
                        <div className="flex-1">
                            <EQLInput
                                value={newEql}
                                onChange={setNewEql}
                                placeholder="Enter EQL expression for this option"
                            />
                        </div>
                        <Button
                            type={ButtonTypes.secondary}
                            icon={<PlusIcon width={16} height={16} />}
                            onClick={handleAddOption}
                            text="Add"
                            isDisabled={!newValue.trim() || !newEql.trim() || options.some(opt => opt.value === newValue.trim())}
                        />
                    </div>
                </div>

                {/* List of existing options */}
                <div className="flex flex-col gap-2">
                    {options.map((option, index) => (
                        <div key={index} className="flex items-start gap-4 p-4 bg-slate-50 rounded">
                            <div className="w-1/3">
                                <div className="text-sm text-slate-500 mb-1">Value</div>
                                <div>{option.value}</div>
                            </div>
                            <div className="flex-1">
                                <div className="text-sm text-slate-500 mb-1">EQL</div>
                                <div>{option.eql}</div>
                            </div>
                            <div className="flex flex-col gap-1">
                                <button
                                    onClick={() => handleMoveOption(index, 'up')}
                                    disabled={index === 0}
                                    className="text-slate-400 hover:text-slate-600 disabled:opacity-50"
                                >
                                    <ChevronUpIcon width={16} height={16} />
                                </button>
                                <button
                                    onClick={() => handleMoveOption(index, 'down')}
                                    disabled={index === options.length - 1}
                                    className="text-slate-400 hover:text-slate-600 disabled:opacity-50"
                                >
                                    <ChevronDownIcon width={16} height={16} />
                                </button>
                                <button
                                    onClick={() => handleRemoveOption(index)}
                                    className="text-slate-400 hover:text-slate-600"
                                >
                                    <XMarkIcon width={16} height={16} />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MultiSelectOptionsEditor; 