import { IExpandedNode, ISuperficialNode, MetricDependency, SUB_RESOURCE_TYPES } from '../../features/models/discover/INode';
import { mapNodeTypeToBackendNodeType, transformBackendSuperficialResourceToLocalNode, transformBackendExpandedNodeToLocalExpandedNode, transformMetricDependenciesResponseToLocal } from './transformers';
import api from '../api';
import { BackendSuperficialResource, BackendExpandedNodeResponse } from './types';
import { customPropertiesApi } from '../customProperties/customProperties';

const formatDate = (date: Date) => {
  const pad = (n: number) => (n < 10 ? '0' : '') + n;

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // Months are 0-indexed in JS
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  return `${year}-${month}-${day}_${hours}:${minutes}:${seconds}`;
};

const generateCSVFilename = () => {
  const now = new Date();
  const formattedDate = formatDate(now);
  return `euno-data-model-${formattedDate}.csv`;
};

export const nodesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getExpandedNode: build.query<IExpandedNode | null, { accountId: number, nodeId: string, additionalProperties?: string[] }>({
      query: ({ accountId, nodeId, additionalProperties = [] }) => {
        const properties = [
          'looker_refinement_chain',
          'has_refinements',
          'derived_type',
          'input_fields',
          'is_identity_transformation',
          'pdt_total_build_time_30d',
          'pdt_builds_last_30d',
          'sponsors',
          'looker_connection_name',
          'dbt_version',
          'native_id',
          'first_seen_at',
          'thumbnail',
          'last_seen_at',
          'updated_at',
          'external_links',
          'is_calculated',
          'tableau_project',
          'tableau_extract_refresh_frequency',
          'unique_id',
          'total_impressions_14d',
          'total_impressions_30d',
          'total_impressions_60d',
          'tableau_impressions_summary',
          'parent_container',
          'native_data_type',
          'dbt_measure_type',
          'dimension_type',
          'normalized_data_type',
          'name',
          'parents',
          'subnodes',
          'type',
          'description',
          'tags',
          'meta',
          'generated_by_delphi',
          'is_trivial_sql',
          'proposals',
          'dbt_materialization_strategy',
          'last_30d_views',
          'last_7d_views',
          'last_accessed_at',
          'favourite_count',
          'git_repo_url',
          'git_repo_branch',
          'last_observed',
          'updated_by',
          'created_at',
          'created_by',
          'package_name',
          'database',
          'database_schema',
          'utl',
          'parent_type',
          'parent_name',
          'allow_promote',
          'raw_code',
          'compiled_code',
          'semantic_code',
          'source_directory',
          'looker_model',
          'looker_folder',
          'looker_project',
          'looker_queries',
          'dbt_project',
          'owner',
          'tableau_workbook',
          'euno_project_id',
          'native_updated_by',
          'native_created_by',
          'table_schema',
          'contained_resources',
          'container_chain',
          'table_properties',
          'has_shift_left_potential',
          'tableau_queries_summary',
          'database_technology',
          'shift_left_project',
          'table_dependencies',
          'metric_type',
          'native_last_data_update',
          'tableau_has_extracts',
          'tableau_view_input_fields_legacy_uri',
          'parent_container_name',
          'has_catalog_entry',
          'distinct_users_14d',
          'distinct_users_30d',
          'distinct_users_60d',
          'synced_raw_code'
        ];
        const eql = `uri = '${nodeId}'`;
        const queryParams = {
          page: '1',
          page_size: '1',
          properties: [...additionalProperties, ...properties].join(','),
          eql: generateEncodedEqlWithDefaults({ eql, withSubResources: true })
        };
        const queryString = new URLSearchParams(queryParams).toString();
        return `accounts/${accountId}/data_model/search?${queryString}`;
      },
      transformResponse: (response: { resources: BackendExpandedNodeResponse[] }, _, { additionalProperties }) => {
        if (response.resources.length === 0) {
          throw new Error('Resource not found');
        }
        return transformBackendExpandedNodeToLocalExpandedNode(response.resources[0], additionalProperties || []);
      },
      providesTags: ['Nodes']
    }),
    getMetricsDependencies: build.query<MetricDependency[], { projectId: number, metrics: string[] }>({
      query: ({ projectId, metrics }) => `projects/${projectId}/metrics_projectables?metrics=${metrics.join(',')}`,
      transformResponse: transformMetricDependenciesResponseToLocal
    }),
    getDataModelResources: build.query<{ items: ISuperficialNode[], total: number }, {
      accountId: number,
      page: number,
      pageSize: number,
      orderBy?: string,
      orderDirection?: 'asc' | 'desc',
      eql?: string,
      additionalProperties?: string[],
      withSubResources?: boolean,
      applyDataModelFilters?: boolean
    }>({
      query: ({
        accountId,
        page,
        pageSize,
        orderBy = 'observed_at',
        orderDirection = 'desc',
        eql,
        additionalProperties = [],
        withSubResources = false,
        applyDataModelFilters = true
      }) => {
        const properties = [
          'dimension_type',
          'metric_type',
          'native_data_type',
          'source_path',
          'derived_type',
          'pdt_builds_last_30d',
          'lookml_view_persistency',
          'has_refinements',
          'tableau_extract_refresh_frequency',
          'tableau_project',
          'first_seen_at',
          'uri',
          'first_observation',
          'utl',
          'name',
          'generated_by_delphi',
          'is_trivial_sql',
          'type',
          'description',
          'tags',
          'database',
          'schema',
          'database_schema',
          'dbt_materialization_strategy',
          'materialized',
          'git_repo_url',
          'git_repo_branch',
          'package_name',
          'dbt_project',
          'unique_id',
          'number_of_dimensions',
          'number_of_columns',
          'number_of_measures',
          'number_of_entities',
          'number_of_metrics',
          'number_of_custom_fields',
          'parents',
          'meta',
          'has_shift_left_potential',
          'owner',
          'tableau_workbook',
          'looker_folder',
          'looker_model',
          'source_directory',
          'looker_host',
          'looker_project',
          'last_7d_views',
          'last_30d_views',
          'total_queries_14d',
          'total_queries_30d',
          'total_queries_60d',
          'distinct_users_14d',
          'distinct_users_30d',
          'distinct_users_60d',
          'parent_type',
          'parent_name',
          'database_technology',
          'total_impressions_14d',
          'total_impressions_30d',
          'total_impressions_60d',
          'distinct_impressions_users_14d',
          'distinct_impressions_users_30d',
          'distinct_impressions_users_60d',
          'native_last_data_update',
          'tableau_has_extracts',
          'is_calculated',
          'parent_container_name',
          'is_identity_transformation',
          'out_of_sync_pre_aggregate_model',
          'is_automatic_pre_aggregated_dbt_model'
        ];
        const queryParams = {
          page: page.toString(),
          page_size: pageSize.toString(),
          properties: [properties, ...additionalProperties].join(','),
          eql: generateEncodedEqlWithDefaults({ eql, withSubResources }),
          sorting: `${orderBy}:${orderDirection}`,
          include_count: 'true',
          apply_default_filter: applyDataModelFilters.toString()
        };
        const queryString = new URLSearchParams(queryParams).toString();
        return `accounts/${accountId}/data_model/search?${queryString}`;
      },
      transformResponse: (response: { resources: BackendSuperficialResource[], count: number }) => {
        return {
          items: response.resources.map(transformBackendSuperficialResourceToLocalNode).filter(n => n !== null).map(n => n as ISuperficialNode),
          total: response.count
        };
      }
    }),
    getFacets: build.query<{ [facet: string]: string[] }, { accountId: number }>({
      query: ({ accountId }) => {
        const facets = ['derived_type', 'lookml_view_persistency', 'tableau_extract_refresh_frequency', 'tags', 'dbt_materialization_strategy', 'materialized', 'meta', 'database', 'database_schema', 'dbt_project', 'type', 'parent_type', 'looker_project', 'looker_folder', 'looker_model', 'source_directory', 'looker_host', 'tableau_project', 'tableau_workbook', 'owner' , 'looker_refinement_chain'];
        return `accounts/${accountId}/facets?properties=${facets.join(',')}`;
      },
      transformResponse: (response: { facets: { [facet: string]: string[] } }) => {
        return response.facets || {};
      }
    }),
    downloadNodesCsv: build.query<void, { accountId: number, eql: string, properties: string, propertyHeaders: string, withSubResources?: boolean }>({
      query: ({ accountId, eql, properties, propertyHeaders, withSubResources = false }) => {
        const encodedEql = generateEncodedEqlWithDefaults({ eql, withSubResources });
        return {
          url: `accounts/${accountId}/data_model/download?eql=${encodedEql}&properties=${properties}&property_headers=${propertyHeaders}`,
          method: 'GET',
          responseHandler: async (response) => {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const filename = generateCSVFilename();
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;

            // Step 7: Trigger the download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Step 8: Revoke the URL to free up memory
            URL.revokeObjectURL(url);
          },
          cache: "no-cache",
        };
      }
    }),
    setCustomPropertyValue: build.mutation<null, { accountId: number, nodeId: string, propertyId: number, value: string | boolean | number | string[] | null}>({
      query: ({ accountId, nodeId, propertyId, value }) => ({
          url: `accounts/${accountId}/custom_properties/${propertyId}/values`,
          method: 'POST',
          body: {
            uris: [nodeId],
            value: value
          }
      }),
      transformResponse: () => null,
      invalidatesTags: ['Nodes']
    }),
    getGenAIResponse: build.query<{ eql: string, confidence: number, clarification_suggestion?: { suggestion?: string, example_prompt?: string } }, { accountId: number, prompt: string }>({
      query: ({ accountId, prompt }) => {
        return `accounts/${accountId}/data_model/genai?prompt=${encodeURIComponent(prompt)}`;
      }
    })
  })
});

export const {
  useGetExpandedNodeQuery,
  useGetMetricsDependenciesQuery,
  useGetDataModelResourcesQuery,
  useLazyGetDataModelResourcesQuery,
  useLazyDownloadNodesCsvQuery,
  useGetFacetsQuery,
  useLazyGetGenAIResponseQuery
} = nodesApi;

const generateEncodedEqlWithDefaults = ({ eql, withSubResources }: { eql?: string, withSubResources: boolean }) => {
  const concatenatedEql = [];
  if (eql) {
    concatenatedEql.push(eql);
  }
  if (!withSubResources) {
    concatenatedEql.push(`NOT (type in (${SUB_RESOURCE_TYPES.map(t => `"${mapNodeTypeToBackendNodeType.get(t)}"`).join(', ')}))`);
  }
  const encodedEql = encodeURIComponent(concatenatedEql.join(' AND '));
  return encodedEql;
};

// Re-export the custom properties endpoints for backward compatibility
export const {
  useGetCustomPropertiesQuery,
  useSetCustomPropertyValueMutation
} = customPropertiesApi;
