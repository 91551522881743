import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import PageLoader from "src/components/loaders/PageLoader";
import { ResourceSidpaneHeader } from "src/features/models/discover/resourceSidepane/header/ResourceSidepaneHeader";
import { ResourceSidepaneTabs } from "src/features/models/discover/resourceSidepane/ResourceSidepaneTabs";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetCustomPropertiesQuery, useGetExpandedNodeQuery } from "src/services/nodes/nodes";
import { CustomProperty } from "src/services/nodes/types";
import { ResourceSidepaneOverview } from "src/features/models/discover/resourceSidepane/overviewTab/ResourceSidepaneOverview";
import { ResourceSidepaneStickySection } from "src/features/models/discover/resourceSidepane/header/ResourceSidepaneStickySection";
import { IExpandedNode } from "src/features/models/discover/INode";
import { ResourceSidepaneSchemaTab } from "src/features/models/discover/resourceSidepane/schemaTab/ResourceSidepaneSchemaTab";
import { ResourceSidepaneRelationshipsTab } from "src/features/models/discover/resourceSidepane/relationshipsTab/ResourceSidepaneRelationshipsTab";
import { ResourceSidepaneAboutTab } from "src/features/models/discover/resourceSidepane/aboutTab/ResourceSidepaneAboutTab";
import { ResourceSidepaneContentsTab } from "src/features/models/discover/resourceSidepane/contentsTab/ResourceSidepaneContentsTab";
import { ResourceSidepaneUsageTab } from "src/features/models/discover/resourceSidepane/usageTab/ResourceSidepaneUsageTab";
import { ResourceSidepaneCustomTab } from "src/features/models/discover/resourceSidepane/customTab/ResourceSidepaneCustomTab";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import { notify } from "src/components/Toaster";
import { ResourceSidepaneUsedFieldsTab } from "src/features/models/discover/resourceSidepane/usedFieldsTab/ResourceSidepaneUsedFieldsTab";
import { events, trackEvent } from "src/infrastructure/analytics";
import { BuiltInResourceTab, SidePaneTabDefinition } from "./types";
import { Bars4Icon, BookOpenIcon, InformationCircleIcon, PresentationChartLineIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import { RelationshipIcon } from "src/assets/images/icons/DelphiIcons";

type ResourceSidepaneLoader = {
    resourceId: string;
    onClose: () => void;
}
const BuiltInTabToIconMap: Record<BuiltInResourceTab, React.ReactNode> = {
    [BuiltInResourceTab.Overview]: <BookOpenIcon width="16" height="16" className="mx-auto" />,
    [BuiltInResourceTab.Schema]: <Bars4Icon width="16" height="16" className="mx-auto" />,
    [BuiltInResourceTab.Contents]: <Squares2X2Icon width="16" height="16" className="mx-auto" />,
    [BuiltInResourceTab.UsedFields]: <Bars4Icon width="16" height="16" className="mx-auto" />,
    [BuiltInResourceTab.Usage]: <PresentationChartLineIcon width="16" height="16" className="mx-auto" />,
    [BuiltInResourceTab.Relationships]: <RelationshipIcon width="16" height="16" className="mx-auto" />,
    [BuiltInResourceTab.About]: <InformationCircleIcon width="16" height="16" className="mx-auto" />,
};

const getTabs = (resource: IExpandedNode | null | undefined, customProperties: CustomProperty[] | undefined) => {
    const tabMap = new Map<string, SidePaneTabDefinition>();
    
    // Add built-in tabs
    Object.values(BuiltInResourceTab).forEach(tab => {
        tabMap.set(tab, {
            name: tab,
            label: tab,
            icon: BuiltInTabToIconMap[tab],
            isBuiltIn: true,
            builtInTab: tab,
        });
    });

    // Add custom property group tabs
    if (resource && customProperties) {
        const groupedProperties = customProperties.reduce((groups, property) => {
            const group = property.metadata.properties_group;
            if (group && (property.content.type.startsWith('manual_') || resource.customProperties[property.name] != null)) {
                const groupProperties = groups.get(group) || [];
                groups.set(group, [...groupProperties, property]);
            }
            return groups;
        }, new Map<string, CustomProperty[]>());

        groupedProperties.forEach((properties, group) => {
            if (properties.length > 0) {
                const sortedProperties = [...properties].sort(
                    (a, b) => (a.metadata.order_in_group || 0) - (b.metadata.order_in_group || 0)
                );
                
                tabMap.set(group, {
                    name: group,
                    label: group,
                    icon: <BookOpenIcon width="16" height="16" className="mx-auto" />,
                    isBuiltIn: false,
                    customProperties: sortedProperties
                });
            }
        });
    }

    return tabMap;
};

export const ResourceSidepane = ({ resourceId: initialResourceID, onClose }: ResourceSidepaneLoader) => {
    const [isClosing, setIsClosing] = useState<boolean>(false);
    const accountId = useSelector(selectActiveAccountId);
    const [searchParams, setSearchParams] = useSearchParams();
    const resourceId: string = searchParams.get('resourceId') || '';
    const activeTab = searchParams.get('tab') || BuiltInResourceTab.Overview;
    const getCustomProperties = useGetCustomPropertiesQuery({ accountId });
    const additionalProperties = getCustomProperties.data?.map(p => p.name) || [];
    const getExpandedResource = useGetExpandedNodeQuery({ accountId, nodeId: resourceId, additionalProperties }, { skip: !resourceId || getCustomProperties.isLoading });
    const expandedResource = getExpandedResource.data;
    const isLoading = getExpandedResource.isFetching;
    const animation = isClosing ? '-right-1/2 duration-200' : 'right-0 duration-200';
    const [historyStack, setHistoryStack] = useState<string[]>([]);
    const allTabs = getTabs(expandedResource, getCustomProperties.data);

    useEffect(() => {
        if (getExpandedResource.data) {
            trackEvent(events.resourceSidepaneOpened, { type: getExpandedResource.data.type, page: window.location.pathname });
        }
    }, [getExpandedResource.data]);

    useEffect(() => {
        if (expandedResource?.name) {
            document.title = `${expandedResource.name} - Euno`;
            if (!searchParams.get('tab')) {
                searchParams.set('tab', BuiltInResourceTab.Overview);
                setSearchParams(searchParams);
            }
        }
        return () => {
            document.title = 'Euno';
        };
    }, [expandedResource, searchParams, setSearchParams]);

    useEffect(() => {
        if (getExpandedResource.error) {
            notify('Failed to load resource', 'error');
        }
    }, [getExpandedResource.error]);

    const setActiveTabWithEvent = (tab: string) => {
        searchParams.set('tab', tab);
        setSearchParams(searchParams);
        trackEvent(events.resourceSidepaneNavigatedToTab, { tab });
    };

    const onCloseClick = () => {
        setIsClosing(true);
        setTimeout(() => {
            searchParams.delete('resourceId');
            searchParams.delete('tab');
            setSearchParams(searchParams);
            onClose();
            setHistoryStack([]);
        }, 200);
    };

    const setResourceId = useCallback((id: string) => {
        searchParams.set('resourceId', id);
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);

    useEffect(() => {
        if (initialResourceID && initialResourceID !== resourceId) {
            setResourceId(initialResourceID);
            setIsClosing(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialResourceID]);

    if (!resourceId) {
        return null;
    }

    const activeTabDefinition = allTabs.get(activeTab);
    return (
        <>
            <div className={`${animation} bg-white w-[33vw] min-w-[740px] rounded-l-2xl border overflow-hidden border-border fixed top-0 z-30 h-screen shadow-lg transition-right sidepane`}>
                <div className="flex flex-col h-full">
                    <ResourceSidpaneHeader historyStack={historyStack} setHistoryStack={setHistoryStack} setResourceId={setResourceId} onClose={onCloseClick} resource={expandedResource || null} />
                    {
                        isLoading && <PageLoader />
                    }
                    {
                        !isLoading && expandedResource && activeTabDefinition && (
                            <div className="flex border-t border-border flex-1">
                                <div className="flex flex-col flex-1">
                                    <ResourceSidepaneStickySection setResourceId={setResourceId} resource={expandedResource} />
                                    <div className="flex flex-col flex-1">
                                        <TabContents tabDefinition={activeTabDefinition} resource={expandedResource} setResourceId={setResourceId} setActiveTab={setActiveTabWithEvent} />
                                    </div>
                                </div>
                                <ResourceSidepaneTabs allTabs={Array.from(allTabs.values())} resource={expandedResource} activeTab={activeTab} setActiveTab={setActiveTabWithEvent} />
                            </div>
                        )
                    }
                    {
                        !expandedResource && getExpandedResource.isError && (
                            <div className="flex flex-col text-base text-center gap-2 text-tertiary w-fit m-auto">
                                <ExclamationTriangleIcon width={20} height={20} className="mx-auto"/>
                                Problem loading resource
                                <Button type={ButtonTypes.secondary} text="Contact support" onClick={() => window.open('mailto:support@euno.ai')} />
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    );
};

type TabContentsProps = {
    tabDefinition: SidePaneTabDefinition;
    resource: IExpandedNode;
    setResourceId: (id: string) => void;
    setActiveTab: (tab: string) => void;
}

const TabContents = ({ tabDefinition, resource, setResourceId, setActiveTab }: TabContentsProps) => {
    if (!tabDefinition.isBuiltIn) {
        return <ResourceSidepaneCustomTab tabDefinition={tabDefinition} resource={resource} />;
    }
    switch (tabDefinition.builtInTab) {
        case BuiltInResourceTab.Overview:
            return <ResourceSidepaneOverview setResourceId={setResourceId} resource={resource} setActiveTab={setActiveTab} />;
        case BuiltInResourceTab.Schema:
            return <ResourceSidepaneSchemaTab setResourceId={setResourceId} resource={resource} />;
        case BuiltInResourceTab.Contents:
            return <ResourceSidepaneContentsTab setResourceId={setResourceId} resource={resource} />;
        case BuiltInResourceTab.Relationships:
            return <ResourceSidepaneRelationshipsTab setResourceId={setResourceId} resource={resource} />;
        case BuiltInResourceTab.About:
            return <ResourceSidepaneAboutTab resource={resource} />;
        case BuiltInResourceTab.Usage:
            return <ResourceSidepaneUsageTab setResourceId={setResourceId} resource={resource} />;
        case BuiltInResourceTab.UsedFields:
            return <ResourceSidepaneUsedFieldsTab setResourceId={setResourceId} resource={resource} />;
        default:
            return null;
    }
};