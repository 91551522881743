import { SyncRunStatus } from "src/features/dataModelSync/types";

export type CreateMetabaseIntegrationRequest = {
    accountId: number;
    user: string;
    password: string;
    host: string;
}

export type CreateLookerIntegrationRequest = {
    accountId: number;
    clientId: string;
    clientSecret: string;
    lookerHost: string;
}

export type GetIngtegrationKeysResponse = {
    name: string;
    id: number;
    created_at: string;
    created_by: string;
    last_used_at: string;
}

export type GetGithubIntegrationResponse = {
    integrations: [
        {
            installation_id: number;
            created_at: string;
            account_id: number;
            account_login: string;
            account_type: string;
            account_url: string;
            account_avatar_url: string;
            account_html_url: string;
            account_node_id: string;
            account_site_admin: true;
            account_repositories_url: string;
        }
    ];
}

export type GetGitlabIntegrationResponse = {
    id: number;
    created_at: string;
    created_by: number;
    domain: string;
    name: string;
}

export type TableauIntegrationConfiguration = {
    connect_uri: string;
    site: string;
    token_name: string;
    token_value: string | undefined;
    ssl_verify: boolean;
    database_hostname_to_platform_instance_map?: { [key: string]: string; };
    project_pattern?: {allow: Array<string>, deny: Array<string>};
}

export type FivetranIntegrationConfiguration = {
    api_key: string;
    api_secret: string | undefined;
    base_url: string | undefined;
}

export type ThoughtSpotIntegrationConfiguration = {
    host: string;
    username: string;
    password: string | undefined;
    ssl_verify: boolean;
}

export type LookerIntegrationConfiguration = {
    client_id: string;
    client_secret: string | undefined;
    host: string;
    connection_mapping?: { [key: string]: string|object; };
}

export type BigQueryIntegrationConfiguration = {
    service_account_key: string | undefined;
    execution_project_id?: string;
    query_location?: string;
    ssl_verify: boolean;
    discovered_projects?: string;
    discover_views?: boolean;
    discover_dataset_labels?: boolean; 
    discover_table_labels?: boolean;
}

export type GenericIntegration = {
    integration_type: GenericIntegrationType;
    name: string;
    configuration: GenericIntegrationConfiguration;
    invalidation_strategy?: GenericInvalidationStrategy;
    id: number;
    active?: boolean;
    schedule?: IntegrationSchedule,
    health: string;
    last_completed_run_end_time?: string;
    created_by: string;
    created_at: Date;
    last_updated_by: string;
    last_updated_at: Date;
    trigger_secret?: string;
    last_run_status: SyncRunStatus;
}

export type SnowflakeIntegrationConfiguration = {
    host: string;
    user: string;
    password: string | undefined;
    role: string;
    warehouse: string;
    ssl_verify: boolean;
    table_to_use_for_query_history?: string;
    use_snowflake_database: boolean;
    database_mapping?: { [key: string]: string; };
    database_pattern?: {allow: Array<string>, deny: Array<string>};
}

export type GenericIntegrationConfiguration = TableauIntegrationConfiguration | LookerIntegrationConfiguration | SnowflakeIntegrationConfiguration | DbtCoreIntegrationConfiguration | FivetranIntegrationConfiguration | BigQueryIntegrationConfiguration | ThoughtSpotIntegrationConfiguration;

export type GenericInvalidationStrategy = {
    ttl_days: number;
}

export type DbtCoreIntegrationConfiguration = {
    schemas_aliases: { [key: string]: string; };
}

export enum GenericIntegrationType {
    tableau = 'tableau',
    snowflake = 'snowflake',
    looker = 'looker',
    dbt_core = 'dbt_core',
    fivetran = 'fivetran',
    thoughtspot = 'thoughtspot',
    bigquery = 'bigquery',
}

export type IntegrationSchedule = {
    time_zone: string;
    repeat_on?: WeekDay[];
    repeat_time?: string; // HH:MM
    repeat_period?: number // in hours
}

export enum WeekDay {
    Sun = 'Sun',
    Mon = 'Mon',
    Tue = 'Tue',
    Wed = 'Wed',
    Thu = 'Thu',
    Fri = 'Fri',
    Sat = 'Sat',
}

export type GitlabProjectResponse = {
    id: number;
    name: string;
    full_name: string;
    private: boolean;
    owner_id: string;
    owner_type: string;
    owner_login: string;
    clone_url: string;
}

export type GetRepositoriesResponse = {
    repos: {
        id: number;
        name: string;
        full_name: string;
        private: boolean;
        owner_id: number;
        owner_type: string;
        owner_login: string;
        clone_url: string;
    }[];
}
