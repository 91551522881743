import { CustomProperty, CustomPropertyType } from "src/services/nodes/types";
import { Chip } from "./Chip";
import DropdownMenu from "src/components/DropdownMenu/DropdownMenu";

type PropertyValueProps = {
    property: CustomProperty;
    value: string | string[] | null | undefined;
    accountId: number;
    nodeId: string;
    setCustomPropertyValue: (params: {
        accountId: number;
        nodeId: string;
        propertyId: number;
        value: string | boolean | number | string[] | null;
    }) => void;
    isManualProperty: (property: CustomProperty) => boolean;
};

export const PropertyValue = ({ 
    property, 
    value, 
    accountId, 
    nodeId, 
    setCustomPropertyValue,
    isManualProperty 
}: PropertyValueProps) => {
    if (property.content.type === CustomPropertyType.manual_multi_select) {
        const selectedValues = value as string[] || [];
        const availableOptions = ((property.content.content as string[]) || []).filter((option: string) => !selectedValues.includes(option));
        
        return (
            <div className="flex gap-1 flex-wrap items-center overflow-visible">
                {selectedValues.map((item: string) => (
                    <Chip
                        key={item}
                        onDelete={() => {
                            const newValues = selectedValues.filter((v: string) => v !== item);
                            setCustomPropertyValue({
                                accountId,
                                nodeId,
                                propertyId: property.id,
                                value: newValues
                            });
                        }}
                    >
                        {item}
                    </Chip>
                ))}
                {availableOptions.length > 0 && (
                    <DropdownMenu
                        items={availableOptions.map(option => ({
                            name: option,
                            onClick: () => {
                                const newValues = [...selectedValues, option];
                                setCustomPropertyValue({
                                    accountId,
                                    nodeId,
                                    propertyId: property.id,
                                    value: newValues
                                });
                            }
                        }))}
                    >
                        <button className="w-6 h-6 flex items-center justify-center border border-slate-200 rounded hover:bg-slate-50">
                            +
                        </button>
                    </DropdownMenu>
                )}
            </div>
        );
    }
    if (value === null || value === undefined) return '-';

    const clearButton = isManualProperty(property) && (
        <button
            onClick={() => setCustomPropertyValue({
                accountId,
                nodeId,
                propertyId: property.id,
                value: null
            })}
            className="ml-1 text-slate-400 hover:text-slate-600"
        >
            ×
        </button>
    );

    switch (property.content.type) {
        case CustomPropertyType.manual_datetime_field:
            return (
                <div className="flex items-center">
                    {new Date(value as string).toLocaleString()}
                    {clearButton}
                </div>
            );
        case CustomPropertyType.manual_single_select:
            return (
                <div className="flex items-center">
                    <Chip>{value || '-'}</Chip>
                    {clearButton}
                </div>
            );
        case CustomPropertyType.manual_boolean_field:
            return (
                <div className="flex items-center">
                    {value ? 'Yes' : 'No'}
                    {clearButton}
                </div>
            );
        case CustomPropertyType.scoped_boolean:
        case CustomPropertyType.boolean:
            return value ? 'Yes' : 'No';
        default:
            return (
                <div className="flex items-center">
                    {String(value)}
                    {clearButton}
                </div>
            );
    }
}; 