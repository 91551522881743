import { EQLInput } from "src/components/EQLInput";

type ScopedBooleanContent = {
    eql: string;
    scope: string;
};

type ScopedBooleanEditorProps = {
    content: ScopedBooleanContent;
    onChange: (content: ScopedBooleanContent) => void;
};

const ScopedBooleanEditor = ({ content, onChange }: ScopedBooleanEditorProps) => {
    return (
        <div className="border-t border-slate-200 pt-6 mt-6">
            <div className="text-sm font-medium text-slate-700 mb-4">Scoped Boolean Configuration</div>
            <div className="flex flex-col gap-4">
                <div className="flex items-start gap-4">
                    <div className="w-32 pt-2">EQL</div>
                    <div className="flex-1">
                        <EQLInput
                            value={content.eql}
                            onChange={(value) => onChange({
                                ...content,
                                eql: value
                            })}
                            placeholder="Enter EQL expression that defines when the property is true"
                        />
                    </div>
                </div>

                <div className="flex items-start gap-4">
                    <div className="w-32 pt-2">Scope</div>
                    <div className="flex-1">
                        <EQLInput
                            value={content.scope}
                            onChange={(value) => onChange({
                                ...content,
                                scope: value
                            })}
                            placeholder="Enter scope EQL (optional)"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScopedBooleanEditor; 