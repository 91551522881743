import { CustomProperty, CustomPropertyType, CustomPropertyContentType, AggregateType, ScopedBooleanContent } from "src/services/nodes/types";
import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import Input from "src/components/form/Input";
import TextArea from "src/components/form/Textarea";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useCreateCustomPropertyMutation, useUpdateCustomPropertyMutation, useDeleteCustomPropertyMutation } from "src/services/customProperties/customProperties";
import { notify } from "src/components/Toaster";
import Select, { Option } from "src/components/form/Select";
import Modal from "src/components/Modal/Modal";
import { useGetUserQuery } from "src/services/users";
import ScopedBooleanEditor from "./propertyTypes/ScopedBooleanEditor";
import SelectOptionsEditor from "./propertyTypes/SelectOptionsEditor";
import MultiSelectOptionsEditor from "./propertyTypes/MultiSelectOptionsEditor";
import { PROPERTY_TYPE_LABELS } from "src/services/nodes/types";
import Checkbox from "src/components/form/Checkbox";

type CustomPropertyFormProps = {
    property: CustomProperty | null;
    isNew: boolean;
    onCancel: () => void;
};

const validTypes = [
    CustomPropertyType.manual_numeric_field,
    CustomPropertyType.manual_datetime_field,
    CustomPropertyType.manual_boolean_field,
    CustomPropertyType.manual_single_select,
    CustomPropertyType.manual_multi_select,
    CustomPropertyType.multi_select,
    CustomPropertyType.scoped_boolean,
];

type ValidationErrorItem = {
    loc: string[];
    msg: string;
    type: string;
    ctx?: Record<string, unknown>;
};

type EQLErrorDetails = {
    error: string;
    line?: number;
    column?: number;
    allowed?: string[] | null;
};

type APIError = {
    status: number;
    data: {
        detail: 
            | ValidationErrorItem[] 
            | string 
            | {
                message: string;
                'error details': EQLErrorDetails;
            };
    };
};

const CustomPropertyForm = ({ property, isNew, onCancel }: CustomPropertyFormProps) => {
    const accountId = useSelector(selectActiveAccountId);
    const { data: currentUser } = useGetUserQuery();
    const [createProperty, { isLoading: isCreating }] = useCreateCustomPropertyMutation();
    const [updateProperty, { isLoading: isUpdating }] = useUpdateCustomPropertyMutation();
    const [deleteProperty, { isLoading: isDeleting }] = useDeleteCustomPropertyMutation();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [formData, setFormData] = useState<Partial<CustomProperty>>(getInitialFormData(property));

    // Update form data when property changes
    useEffect(() => {
        setFormData(getInitialFormData(property));
    }, [property]);

    // Initialize owner to current user for new properties
    useEffect(() => {
        if (isNew && currentUser?.email && !formData.metadata?.owner) {
            setFormData(prev => ({
                ...prev,
                metadata: {
                    description: prev.metadata?.description ?? null,
                    display_name: prev.metadata?.display_name ?? null,
                    properties_group: prev.metadata?.properties_group ?? null,
                    order_in_group: prev.metadata?.order_in_group ?? 0,
                    owner: currentUser.email,
                    show_in_overview: prev.metadata?.show_in_overview ?? false
                }
            }));
        }
    }, [isNew, currentUser, formData.metadata?.owner]);

    const canTakeOwnership = !isNew && formData.metadata?.owner !== currentUser?.email;

    const handleTypeChange = (option: Option | Option[]) => {
        if (!option || Array.isArray(option)) return;
        
        const newType = option.value as CustomPropertyType;
        setFormData({
            ...formData,
            content: {
                type: newType,
                content: getDefaultContentForType(newType)
            }
        });
    };

    const handleSave = async () => {
        try {
            if (isNew) {
                await createProperty({
                    accountId,
                    property: formData as Omit<CustomProperty, 'id' | 'last_updated_at' | 'last_updated_by' | 'created_by' | 'created_at' | 'last_calculation_timeout'>
                }).unwrap();
                notify('Custom property created successfully', 'success');
            } else if (property?.id) {
                await updateProperty({
                    accountId,
                    propertyId: property.id,
                    property: formData
                }).unwrap();
                notify('Custom property updated successfully', 'success');
            }
            onCancel();
        } catch (error: unknown) {
            const apiError = error as APIError;
            if ((apiError?.status === 422 || apiError?.status === 400) && apiError.data?.detail) {
                let details: string;
                if (Array.isArray(apiError.data.detail)) {
                    details = apiError.data.detail.map((err) => {
                        const field = err.loc[err.loc.length - 1];
                        const fieldName = field.charAt(0).toUpperCase() + field.slice(1).replace(/_/g, ' ');
                        return `${fieldName}: ${err.msg}`;
                    }).join(', ');
                } else if (typeof apiError.data.detail === 'object' && 'message' in apiError.data.detail) {
                    const eqlError = apiError.data.detail['error details'];
                    details = `EQL Error: ${eqlError.error}${eqlError.line ? ` (Line ${eqlError.line}, Column ${eqlError.column})` : ''}`;
                } else {
                    details = typeof apiError.data.detail === 'string' ? apiError.data.detail : 'Validation error';
                }
                notify(`Validation error: ${details}`, 'error');
            } else {
                notify(`Failed to ${isNew ? 'create' : 'update'} custom property: ${error instanceof Error ? error.message : 'Unknown error'}`, 'error');
            }
        }
    };

    const handleDelete = async () => {
        if (!property?.id) return;
        
        try {
            await deleteProperty({
                accountId,
                propertyId: property.id
            }).unwrap();
            notify('Custom property deleted successfully', 'success');
            onCancel();
        } catch (error) {
            notify(`Failed to delete custom property: ${error instanceof Error ? error.message : 'Unknown error'}`, 'error');
        }
        setShowDeleteModal(false);
    };

    const updateMetadata = (
        formData: Partial<CustomProperty>, 
        updates: Partial<typeof formData.metadata>
    ) => ({
        ...formData,
        metadata: {
            description: formData.metadata?.description ?? null,
            display_name: formData.metadata?.display_name ?? null,
            properties_group: formData.metadata?.properties_group ?? null,
            order_in_group: formData.metadata?.order_in_group ?? 0,
            owner: formData.metadata?.owner ?? '',
            show_in_overview: formData.metadata?.show_in_overview ?? false,
            ...updates
        }
    });

    return (
        <>
            <div className="border border-border rounded-lg p-6">
                <div className="flex justify-between items-center mb-6">
                    <div className="text-lg">
                        {isNew ? 'Create New Property' : 'Edit Property'}
                    </div>
                    {!isNew && (
                        <Button
                            type={ButtonTypes.danger}
                            text="Delete"
                            onClick={() => setShowDeleteModal(true)}
                            isLoading={isDeleting}
                        />
                    )}
                </div>
                
                <div className="flex flex-col gap-4">
                    {/* Metadata Info Box - only show when editing */}
                    {!isNew && property && (
                        <div className="bg-slate-50 p-4 rounded-lg mb-4">
                            <div className="text-sm font-medium text-slate-700 mb-2">Property Information</div>
                            <div className="grid grid-cols-2 gap-2 text-sm">
                                <div className="text-slate-500">Created by</div>
                                <div>{property.created_by}</div>
                                <div className="text-slate-500">Created at</div>
                                <div>{new Date(property.created_at).toLocaleString()}</div>
                                <div className="text-slate-500">Last updated by</div>
                                <div>{property.last_updated_by}</div>
                                <div className="text-slate-500">Last updated at</div>
                                <div>{new Date(property.last_updated_at).toLocaleString()}</div>
                            </div>
                        </div>
                    )}

                    {/* Basic Information Section */}
                    <div className="mb-6">
                        <div className="text-sm font-medium text-slate-700 mb-4">Basic Information</div>
                        <div className="flex flex-col gap-4">
                            <div className="flex items-center gap-4">
                                <div className="w-32">Name</div>
                                <Input
                                    value={formData.name || ''}
                                    onInputChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    disabled={!isNew}
                                    placeholder="Enter property name"
                                />
                            </div>

                            <div className="flex items-center gap-4">
                                <div className="w-32">Type</div>
                                <Select
                                    options={validTypes.map((type) => ({
                                        value: type,
                                        label: PROPERTY_TYPE_LABELS[type] || type
                                    }))}
                                    value={formData.content?.type ?? null}
                                    onChange={handleTypeChange}
                                    isDisabled={!isNew}
                                    className="w-full"
                                />
                            </div>

                            <div className="flex items-center gap-4">
                                <div className="w-32">Display Name</div>
                                <Input
                                    value={formData.metadata?.display_name || ''}
                                    onInputChange={(e) => setFormData(updateMetadata(formData, { display_name: e.target.value }))}
                                    placeholder="Enter display name"
                                />
                            </div>

                            <div className="flex items-start gap-4">
                                <div className="w-32 pt-2">Description</div>
                                <TextArea
                                    value={formData.metadata?.description || ''}
                                    onChange={(e) => setFormData(updateMetadata(formData, { description: e.target.value }))}
                                    placeholder="Enter property description"
                                    rows={4}
                                />
                            </div>

                            <div className="flex items-center gap-4">
                                <div className="w-32">Owner</div>
                                <div className="flex-1 flex gap-2">
                                    <Input
                                        value={formData.metadata?.owner || ''}
                                        disabled={true}
                                        placeholder="Property owner"
                                    />
                                    {canTakeOwnership && (
                                        <Button
                                            type={ButtonTypes.secondary}
                                            text="Take Ownership"
                                            onClick={() => setFormData(updateMetadata(formData, { owner: currentUser?.email || '' }))}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className="flex items-center gap-4">
                                <div className="w-32">Show in Overview</div>
                                <Checkbox
                                    value={formData.metadata?.show_in_overview || false}
                                    setValue={(checked) => setFormData(updateMetadata(formData, { show_in_overview: checked }))}
                                />
                            </div>

                            <div className="flex items-center gap-4">
                                <div className="w-32">Group</div>
                                <div className="flex-1 flex gap-2">
                                    <Input
                                        value={formData.metadata?.properties_group || ''}
                                        onInputChange={(e) => setFormData(updateMetadata(formData, { properties_group: e.target.value || null }))}
                                        placeholder="Enter group name (or leave blank to hide the property from the side panel)"
                                    />
                                    {formData.metadata?.properties_group && (
                                        <Button
                                            type={ButtonTypes.secondary}
                                            text="Hide"
                                            onClick={() => setFormData(updateMetadata(formData, { properties_group: null, order_in_group: 0 }))}
                                        />
                                    )}
                                </div>
                            </div>

                            {formData.metadata?.properties_group && (
                                <div className="flex items-center gap-4">
                                    <div className="w-32">Order in Group</div>
                                    <Input
                                        type="number"
                                        value={formData.metadata?.order_in_group?.toString() || '0'}
                                        onInputChange={(e) => setFormData(updateMetadata(formData, { order_in_group: parseInt(e.target.value) || 0 }))}
                                        placeholder="Enter order (0-999)"
                                        min="0"
                                        max="999"
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Type-specific Configuration */}
                    {formData.content?.type === CustomPropertyType.scoped_boolean && (
                        <ScopedBooleanEditor
                            content={formData.content.content as ScopedBooleanContent || { eql: '', scope: 'true' }}
                            onChange={(newContent) => setFormData({
                                ...formData,
                                content: {
                                    type: CustomPropertyType.scoped_boolean,
                                    content: newContent
                                }
                            })}
                        />
                    )}
                    {(formData.content?.type === CustomPropertyType.manual_single_select ||
                        formData.content?.type === CustomPropertyType.manual_multi_select) && (
                        <SelectOptionsEditor
                            options={formData.content.content as string[] || []}
                            onChange={(newOptions) => setFormData({
                                ...formData,
                                content: {
                                    type: formData.content!.type,
                                    content: newOptions
                                }
                            })}
                        />
                    )}
                    {formData.content?.type === CustomPropertyType.multi_select && (
                        <MultiSelectOptionsEditor
                            options={formData.content.content as { value: string; eql: string; }[] || []}
                            onChange={(newOptions) => setFormData({
                                ...formData,
                                content: {
                                    type: CustomPropertyType.multi_select,
                                    content: newOptions
                                }
                            })}
                        />
                    )}

                    <div className="flex justify-end gap-2 mt-4">
                        <Button
                            type={ButtonTypes.secondary}
                            text="Cancel"
                            onClick={onCancel}
                        />
                        <Button
                            type={ButtonTypes.primary}
                            text={isNew ? 'Create' : 'Save'}
                            onClick={handleSave}
                            isLoading={isCreating || isUpdating}
                        />
                    </div>
                </div>
            </div>

            <Modal
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                title={`Delete ${property?.metadata.display_name || property?.name}?`}
                buttons={[
                    { 
                        text: "Cancel", 
                        onClick: () => setShowDeleteModal(false), 
                        type: ButtonTypes.secondary 
                    },
                    { 
                        text: "Delete", 
                        onClick: handleDelete, 
                        type: ButtonTypes.danger 
                    }
                ]}
            >
                <div className="text-text-primary">
                    Are you sure you want to delete this custom property? This action cannot be undone.
                </div>
            </Modal>
        </>
    );
};

function getDefaultContentForType(type: CustomPropertyType): CustomPropertyContentType {
    switch (type) {
        case CustomPropertyType.manual_multi_select:
        case CustomPropertyType.manual_single_select:
            return [];
        case CustomPropertyType.manual_text_field:
        case CustomPropertyType.manual_numeric_field:
        case CustomPropertyType.manual_datetime_field:
        case CustomPropertyType.manual_boolean_field:
            return null;
        case CustomPropertyType.boolean:
            return '';
        case CustomPropertyType.scoped_boolean:
            return {
                eql: '',
                scope: 'true'
            };
        case CustomPropertyType.multi_select:
            return [];
        case CustomPropertyType.aggregate_relationships:
            return {
                applies_to_eql: '',
                type_of_relationship: '',
                target_eql: '',
                aggregation: AggregateType.count
            };
        case CustomPropertyType.ai_select:
            return {
                options: [],
                prompt: '',
                properties: [],
                scope: ''
            };
        default:
            return null;
    }
}

// Helper function to get initial form data
function getInitialFormData(property: CustomProperty | null): Partial<CustomProperty> {
    if (property) {
        return { ...property };
    }
    return {
        name: '',
        metadata: {
            description: null,
            properties_group: null,
            order_in_group: 0,
            display_name: null,
            owner: '',
            show_in_overview: false
        },
        content: {
            type: CustomPropertyType.manual_text_field,
            content: null
        }
    };
}

export default CustomPropertyForm; 