import { useSelector } from "react-redux";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetCustomPropertiesQuery } from "src/services/nodes/nodes";
import { CustomProperty } from "src/services/nodes/types";
import { PlusIcon, XMarkIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import { useEffect, useState } from "react";
import CustomPropertyForm from "./CustomPropertyForm";
import { PROPERTY_TYPE_LABELS } from "src/services/nodes/types";
import Input from "src/components/form/Input";

const CustomPropertiesSection = () => {
    const accountId = useSelector(selectActiveAccountId);
    const { data: customProperties = [] } = useGetCustomPropertiesQuery({ accountId });
    const [selectedProperty, setSelectedProperty] = useState<CustomProperty | null>(null);
    const [isCreatingNew, setIsCreatingNew] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const filteredProperties = customProperties.filter(property => {
        const searchLower = searchTerm.toLowerCase();
        return property.name.toLowerCase().includes(searchLower) || 
               (property.metadata.display_name?.toLowerCase() || '').includes(searchLower);
    });

    // Reset form when properties data changes
    useEffect(() => {
        if (selectedProperty) {
            const updatedProperty = customProperties.find(p => p.id === selectedProperty.id);
            if (updatedProperty) {
                setSelectedProperty(updatedProperty);
            }
        }
    }, [customProperties, selectedProperty]);

    const handleAddNew = () => {
        setSelectedProperty(null);
        setIsCreatingNew(true);
    };

    const handleSelectProperty = (property: CustomProperty) => {
        // Create a deep copy of the property to avoid reference issues
        setSelectedProperty(JSON.parse(JSON.stringify(property)));
        setIsCreatingNew(false);
    };

    return (
        <div className="flex gap-8">
            {/* Left side - Property List */}
            <div className="w-64 flex flex-col gap-4">
                <div className="flex items-center justify-between">
                    <div className="text-lg text-text-primary">Custom Properties</div>
                    <Button
                        type={ButtonTypes.secondary}
                        icon={<PlusIcon width="16" height="16" />}
                        text="Add new"
                        onClick={handleAddNew}
                    />
                </div>
                <div className="relative">
                    <Input
                        value={searchTerm}
                        onInputChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search properties..."
                        icon={<MagnifyingGlassIcon width={16} height={16} className="text-slate-400" />}
                    />
                    {searchTerm && (
                        <button
                            onClick={() => setSearchTerm('')}
                            className="absolute right-2 top-1/2 -translate-y-1/2 text-slate-400 hover:text-slate-600"
                        >
                            <XMarkIcon width={16} height={16} />
                        </button>
                    )}
                </div>
                <div className="flex flex-col gap-2">
                    {filteredProperties.map((property) => (
                        <div
                            key={property.id}
                            onClick={() => handleSelectProperty(property)}
                            className={`p-3 rounded cursor-pointer border ${
                                selectedProperty?.id === property.id
                                    ? 'border-primary bg-primary-light'
                                    : 'border-border hover:border-primary'
                            }`}
                        >
                            <div className="font-medium truncate">{property.metadata.display_name || property.name}</div>
                            <div className="text-sm text-slate-500 truncate">
                                {PROPERTY_TYPE_LABELS[property.content.type] || property.content.type}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Right side - Property Form */}
            <div className="flex-1">
                {(selectedProperty || isCreatingNew) ? (
                    <CustomPropertyForm 
                        property={selectedProperty}
                        isNew={isCreatingNew}
                        onCancel={() => {
                            setSelectedProperty(null);
                            setIsCreatingNew(false);
                        }}
                    />
                ) : (
                    <div className="h-full flex items-center justify-center text-slate-500">
                        Select a property to edit or create a new one
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomPropertiesSection; 