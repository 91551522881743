import {FunctionComponent, SVGProps} from "react";
import {DbtCloudIcon, LookerIcon, SnowflakeIcon, TableauIcon, FivetranIcon, ThoughtspotIcon, BigQueryIcon} from "src/assets/images/icons/DelphiIcons";
import { GenericIntegrationType } from "src/services/integrations/types";

export const SOURCE_ICONS: Map<GenericIntegrationType, FunctionComponent<SVGProps<SVGSVGElement>>> = new Map([
  [GenericIntegrationType.looker, LookerIcon],
  [GenericIntegrationType.tableau, TableauIcon],
  [GenericIntegrationType.dbt_core, DbtCloudIcon],
  [GenericIntegrationType.snowflake, SnowflakeIcon],
  [GenericIntegrationType.fivetran, FivetranIcon],
  [GenericIntegrationType.thoughtspot, ThoughtspotIcon],
  [GenericIntegrationType.bigquery, BigQueryIcon]
]);