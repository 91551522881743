import { useState, KeyboardEvent } from "react";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useLazyGetGenAIResponseQuery } from "src/services/nodes/nodes";
import Modal from "./Modal/Modal";
import { ButtonTypes } from "./button/types";
import Input from "./form/Input";

type Text2EQLModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onGenerated: (eql: string) => void;
};

export const Text2EQLModal = ({ isOpen, onClose, onGenerated }: Text2EQLModalProps) => {
    const accountId = useSelector(selectActiveAccountId);
    const [getGenAIResponse] = useLazyGetGenAIResponseQuery();
    const [isLoading, setIsLoading] = useState(false);
    const [promptText, setPromptText] = useState('');

    const handleGenerate = async () => {
        if (isLoading) return;
        
        try {
            setIsLoading(true);
            const response = await getGenAIResponse({ 
                accountId, 
                prompt: promptText 
            }).unwrap();
            
            onGenerated(response.eql);
            onClose();
            setPromptText('');
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
            e.preventDefault();
            handleGenerate();
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => !isLoading && onClose()}
            title="Help me write this expression"
            buttons={[
                { 
                    text: 'Cancel', 
                    type: ButtonTypes.secondary, 
                    onClick: onClose,
                    isDisabled: isLoading
                },
                { 
                    text: isLoading ? 'Generating...' : 'Generate EQL',
                    onClick: handleGenerate,
                    type: ButtonTypes.primary,
                    isLoading: isLoading,
                    isDisabled: !promptText.trim() || isLoading
                }
            ]}
        >
            <div className="p-4">
                <Input
                    type="text"
                    value={promptText}
                    onInputChange={(e) => setPromptText(e.target.value)}
                    placeholder="What are all the dashboards that are downstream of the model 'users'?"
                    onKeyDown={handleKeyDown}
                    disabled={isLoading}
                    focus={true}
                />
                <div className="text-lilac-400 w-fit ml-auto mt-2 text-sm">
                    Press <span className="font-semibold text-lilac-600">
                        {navigator.platform.includes('Mac') ? 'Cmd + Enter' : 'Ctrl + Enter'}
                    </span> to generate
                </div>
            </div>
        </Modal>
    );
}; 