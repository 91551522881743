import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import { SelectFilter } from "src/components/Table/TableFilters/SelectFilter";
import { FilterValue, SelectFilterOption } from "src/components/Table/TableFilters/types";
import { Filter } from "src/features/models/discover/toolbar/filters/Types";
import { CustomProperty, MultiSelectOption } from "src/services/nodes/types";
const optionIcon = <AdjustmentsHorizontalIcon width="16" height="16" />;

export const getCustomFilters = (customProperties: CustomProperty[]) => {
    const allowedCustomPropertyTypes = ['boolean', 'multi_select', 'scoped_boolean'];
    const customFilters: Filter[] = [];
    for (const prop of customProperties) {
        if (!allowedCustomPropertyTypes.includes(prop.content.type)) continue;
        let options: SelectFilterOption[];
        let thisGetEql: (value: FilterValue) => string;
        if (prop.content.type === 'boolean') {
            options = booleanOptions;
            thisGetEql = (value: FilterValue) => getEql(prop.name, value, 'boolean');
        } else if (prop.content.type === 'scoped_boolean') {
            options = booleanOptions;
            thisGetEql = (value: FilterValue) => getEql(prop.name, value, 'boolean');
        } else if (prop.content.type === 'multi_select') {
            options = (prop.content.content as MultiSelectOption[]).map(({ value }) => ({ label: value, value, icon: optionIcon } as SelectFilterOption));
            thisGetEql = (value: FilterValue) => getEql(prop.name, value, 'multi_select');
        } else {
            console.error(`Invalid custom property type: ${prop.content.type}`);
            continue;
        }
        customFilters.push({
            name: prop.name,
            menuIcon: <AdjustmentsHorizontalIcon width="18" height="18" className="mr-1.5" />,
            value: null,
            badge: ({ value }: { value: FilterValue }) => `${prop.name}: ${value}`,
            isDisabled: false,
            getEql: thisGetEql,
            component: ({ value, onChange }) => (
                <SelectFilter
                    label={prop.name}
                    options={options}
                    isMulti={prop.content.type === 'multi_select'}
                    value={value}
                    onChange={onChange}
                />
            ),
            group: 'Custom'
        });
    }
    return customFilters;
};

const booleanOptions: SelectFilterOption[] = [{ label: 'Yes', value: 'Yes', icon: optionIcon }, { label: 'No', value: 'No', icon: optionIcon }];
const getEql = (propertyName: string, value: FilterValue, type: 'boolean' | 'multi_select') => {
    if (!value) return '';
    switch (type) {
        case 'boolean':
            return `(${propertyName} IS ${value === "Yes" ? 'TRUE' : 'FALSE'})`;
        case 'multi_select':
            return `(${propertyName} IN ('${value.split(',').join("','")}'))`;
        default:
            console.error(`Invalid filter type: ${type}`);
            return '';
    }
};