import { useState } from 'react';
import { useFlags } from "launchdarkly-react-client-sdk";
import Layout from 'src/components/layout/Layout';
import TopBar from 'src/components/layout/TopBar';
import Footer from 'src/components/layout/Footer';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from 'src/infrastructure/state/slices/activeAccountSlice';
import { useGetAccountsQuery } from 'src/services/accounts/accounts';
import { PageTabs } from 'src/components/PageTabs';
import IntegrationKeysSection from './IntegrationKeysSection';
import IntegrationsSection from './integrations/IntegrationsSection';
import AccountOperations from './AccountOperations';
import CustomPropertiesSection from './customProperties/CustomPropertiesSection';

type AccountTab = 'linkedAccounts' | 'operations' | 'customProperties';

const Account = () => {
  const { showSourcesTabInNavigation, showCustomPropertiesManagement } = useFlags();
  const accountId = useSelector(selectActiveAccountId);
  const getAccountsQuery = useGetAccountsQuery();
  const accountName = getAccountsQuery.data?.find(account => account.id === accountId)?.name || '';
  const [tab, setTab] = useState<AccountTab>('linkedAccounts');

  return (
    <Layout>
      <TopBar>
        <div className="font-medium text-primary">{accountName}</div>
        <PageTabs
          tabs={[
            {
              name: showSourcesTabInNavigation ? 'Linked accounts' : 'Integrations',
              isActive: tab === 'linkedAccounts',
              onClick: () => setTab('linkedAccounts')
            },
            ...(showCustomPropertiesManagement ? [{ 
              name: 'Custom Properties', 
              isActive: tab === 'customProperties', 
              onClick: () => setTab('customProperties') 
            }] : []),
            { 
              name: 'Operations log', 
              isActive: tab === 'operations', 
              onClick: () => setTab('operations') 
            }
          ]}
        />
      </TopBar>
      <div className="mx-24 min-h-[94vh] pl-16 py-8 flex flex-col">
        {tab === 'linkedAccounts' && (
          <>
            <IntegrationsSection />
            <IntegrationKeysSection />
          </>
        )}
        {tab === 'operations' && <AccountOperations />}
        {showCustomPropertiesManagement && tab === 'customProperties' && <CustomPropertiesSection />}
        <Footer />
      </div>
    </Layout>
  );
};

export default Account;
