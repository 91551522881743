/* eslint-disable @typescript-eslint/no-explicit-any */
import Layout from "src/components/layout/Layout";
import { CustomEvent, Filter, FilterGroup, LuzmoDashboardComponent } from '@luzmo/react-embed';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGetLuzmoDashboardQuery, useGetLuzmoEmbedCredentialsQuery, useLazyGetLuzmoColumnsQuery } from "src/services/accounts/accounts";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import PageLoader from "src/components/loaders/PageLoader";
import OverlayLoader from "src/components/loaders/OverlayLoader";
import { notify } from "src/components/Toaster";
import { PageTabs } from "src/components/PageTabs";
import { LuzmoCustomEventColumn, LuzmoCustomEventName } from "src/features/dashboards/types";
import { extractErrorMessage } from "src/services/api";
import { useSearchParams } from "react-router-dom";
import { discoverTableColumns, subResourcesDiscoverColumns } from "src/features/models/discover/view/table/discoverTableColumns";
import { ChartBarIcon } from "@heroicons/react/24/outline";
import { ResourceSidepane } from "src/features/models/discover/resourceSidepane/ResourceSidepane";
import { events, trackEvent } from "src/infrastructure/analytics";

export const DashboardsPage = () => {
    const ref = useRef<any>(null);
    const accountId = useSelector(selectActiveAccountId);
    const getLuzmoEmbedCredentials = useGetLuzmoEmbedCredentialsQuery({ accountId });
    const dashboards = useMemo(() => getLuzmoEmbedCredentials.data?.dashboards || [], [getLuzmoEmbedCredentials.data]);
    const [selectedNode, setSelectedNode] = useState<string | null>(null);
    const [eventLoading, setEventLoading] = useState<boolean>(false);
    const [getLuzmoColumns] = useLazyGetLuzmoColumnsQuery();
    const [searchParams, setSearchParams] = useSearchParams();
    const dashboardId = useMemo(() => searchParams.get('dashboardId'), [searchParams]);
    const getLuzmoDashboard = useGetLuzmoDashboardQuery({ accountId, dashboardId: dashboardId || '' }, { skip: !dashboardId });
    const setDashboardId = useCallback((id: string) => {
        searchParams.set('dashboardId', id);
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);
    const tabs = useMemo(() => dashboards.map(d => ({ name: d.name, isActive: dashboardId === d.id, onClick: () => setDashboardId(d.id) })), [dashboards, dashboardId, setDashboardId]);

    useEffect(() => {
        if (getLuzmoEmbedCredentials.error) {
            notify('Failed to fetch Luzmo embed credentials', 'error');
            console.error(getLuzmoEmbedCredentials.error);
        }
    }, [getLuzmoEmbedCredentials.error]);

    useEffect(() => {
        if (dashboards.length && !dashboardId) {
            setDashboardId(dashboards[0].id);
        }
    }, [dashboards, dashboardId, setDashboardId]);

    const onCustomEvent = (event: CustomEvent | null) => {
        if (!event) return;
        const resourceUri = getResourceUtl(event);
        switch (event.data.data.event) {
            case LuzmoCustomEventName.showDetails:
                if (!resourceUri) {
                    notify('Failed to get resource URI', 'error');
                    return;
                }
                trackEvent(events.resourceOpenedInSidepaneFromDashboard);
                setSelectedNode(resourceUri);
                break;
            case LuzmoCustomEventName.showInDataModel:
            case LuzmoCustomEventName.showPotentialConflicts:
                goToDataModel(event);
                break;
        }
    };

    const filterToUrlQuery = async (filter: Filter): Promise<string> => {
        const columnId = filter.parameters?.[0]?.columnId || '';
        const value = filter.parameters?.[1] || '';
        const columns = await getLuzmoColumns({ accountId, columnIds: [columnId] });
        const localColumnName = propertyToLocalColumnName(columns.data?.[0]?.name || '');
        let queryValue = '';
        switch (filter.expression) {
            case '? = ?':
                queryValue = `${value}`;
                break;
            case '? in ?':
                if (typeof value === 'object' && 'value' in value && Array.isArray(value.value)) {
                    queryValue = value.value.join(',');
                }
                else if (Array.isArray(value)) {
                    queryValue = value.join(',');
                }
                else {
                    throw new Error('Expected an array value for filter expression: ? in ?');
                }
                break;
            default:
                throw new Error(`Unsupported filter expression: ${filter.expression}`);
        }
        return `${localColumnName}=${queryValue}`;
    };

    const getColumnsQuery = async (itemId: string, columns: LuzmoCustomEventColumn[]) => {
        let columnsQuery = '';
        const luzmoVizItem = (getLuzmoDashboard.data as any)?.contents?.views?.[0]?.items?.find((i: any) => i?.id === itemId);
        if (luzmoVizItem) {
            const labels = (columns)?.map(c => c.label) || [];
            const columnIds = labels.map(l => labelToColumnId(luzmoVizItem, l));
            const columnMappings = await getLuzmoColumns({ accountId, columnIds });
            const sortedPropertyNames = columnIds.map(id => columnMappings.data?.find(c => c.id === id)?.name || '');
            const localColumnNames = sortedPropertyNames.map(p => propertyToLocalColumnName(p));
            columnsQuery = `&columns=${localColumnNames.join(',')}`;
        } else {
            notify('Failed to get Luzmo viz item', 'error');
            console.error('Failed to get Luzmo viz item', getLuzmoDashboard.data);
        }
        return columnsQuery;
    };
    const getFilterQuery = async (itemId: string, event: CustomEvent) => {
        let filterQuery = '';
        if (event.data.data.event === LuzmoCustomEventName.showPotentialConflicts) {
            const resourceName = getResourceName(event);
            if (!resourceName) {
                notify('Failed to get resource name', 'error');
                return;
            }
            filterQuery = `&eql=type="tableau_measure"+and+is_calculated+is+true+and+name~"${encodeURIComponent(resourceName)}"&filterMode=eql`;
        } else {
            const filterGroups: FilterGroup[] = await ref.current?.getFilters() || [];
            const allFilters = filterGroups.flatMap(fg => fg.filters);
            const relevantFilters = allFilters.filter(f => f.properties?.itemId === itemId || f.properties?.origin !== 'itemFilter');
            const dedupedRelevantFilters = relevantFilters.reverse().filter((f, i, arr) => arr.findIndex(f2 => f2.parameters?.[0]?.columnId === f.parameters?.[0]?.columnId) === i);
            const filterQueries = await Promise.all(dedupedRelevantFilters.map(filterToUrlQuery));
            filterQuery = `&${filterQueries.join('&')}`;
        }
        return filterQuery;
    };

    const goToDataModel = async (event: CustomEvent) => {
        try {
            setEventLoading(true);
            const categoryDrillDown = event.data.data.category as any;
            const itemId = event.data.itemId as string;
            const categoryQuery = categoryDrillDown ? `&${categoryDrillDown.label}=${categoryDrillDown.value}` : '';
            const [filterQuery, columnsQuery] = await Promise.all([getFilterQuery(itemId, event), getColumnsQuery(itemId, event.data.data.columns as LuzmoCustomEventColumn[])]);
            const url = `/data-model?view=table${columnsQuery}${filterQuery}${categoryQuery}&ignoreSavedTable`;
            trackEvent(events.resourceOpenedInDataModelFromDashboard);
            window.open(url, '_blank');
            setEventLoading(false);
        } catch (e) {
            setEventLoading(false);
            notify(extractErrorMessage(e).message, 'error');
            console.error(e);
        }
    };

    return (
        <Layout>
            <div className="overflow-y-auto max-h-[100vh]">
                {
                    getLuzmoEmbedCredentials.isLoading && <PageLoader />
                }
                {
                    getLuzmoEmbedCredentials.data?.dashboards?.length === 0 && !getLuzmoEmbedCredentials.isLoading && (
                        <NoDashboardsScreen />
                    )
                }
                {
                    getLuzmoEmbedCredentials.data?.token_id && getLuzmoEmbedCredentials.data?.token_secret && dashboardId && (
                        <>
                            <div className="p-4">
                                <PageTabs tabs={tabs} />
                            </div>
                            {
                                eventLoading && <OverlayLoader />
                            }
                            <LuzmoDashboardComponent
                                ref={ref}
                                authKey={getLuzmoEmbedCredentials.data.token_id}
                                authToken={getLuzmoEmbedCredentials.data.token_secret}
                                dashboardSlug={dashboardId}
                                switchScreenModeOnResize={false}
                                loaderSpinnerColor="rgb(0, 81, 126)"
                                loaderSpinnerBackground="rgb(236 248 255)"
                                customEvent={(event: any) => onCustomEvent(event.detail as CustomEvent)}
                            />
                        </>
                    )
                }
            </div>
            <ResourceSidepane onClose={() => setSelectedNode(null)} resourceId={selectedNode || ''} />
        </Layout>
    );
};

const getResourceUtl = (event: CustomEvent): string | null => {
    const columns = event.data.data.columns as LuzmoCustomEventColumn[] | undefined;
    if (!columns) return null;
    const uriColumn = columns.find(c => c.label.toLocaleLowerCase() === 'utl' || c.label.toLocaleLowerCase() === 'uri');
    return uriColumn?.value.toString() || null;
};

const getResourceName = (event: CustomEvent): string | null => {
    const columns = event.data.data.columns as LuzmoCustomEventColumn[] | undefined;
    if (!columns) return null;
    const nameColumn = columns.find(c => c.label.toLocaleLowerCase() === 'name');
    return nameColumn?.value.toString() || null;
};

const propertyToLocalColumnName = (property: string): string => {
    return [...discoverTableColumns, ...subResourcesDiscoverColumns].find(c => c.property === property)?.name || property;
};

const NoDashboardsScreen = () => {
    return (
        <div className="w-fit flex flex-col items-center text-center mx-auto mt-[20vh]">
            <ChartBarIcon className="w-20 h-20 text-slate-200" />
            <div className="mt-5 text-secondary text-lg">Nothing here.</div>
            <div className="mt-2 text-tertiary w-60">Oh no! Looks like this account does not have any dashboards yet.</div>
        </div>
    );
};

const labelToColumnId = (item: any, label: string): string => {
    const slots = item?.slots || [];
    const columnSlot = slots.find((s: any) => s?.name === "columns");
    const columnId = columnSlot?.content?.find((c: any) => c?.label?.en === label)?.column || '';
    return columnId;
};
