export const eunoLinks = {
    DATA_APPLICATION_SYNC_METRICS_DOCUMENTATION: "https://docs.euno.ai/automations/data-application-sync#metrics",
    EQL_DOCUMENTATION: "https://docs.euno.ai/guides/euno-query-language-eql",
    DBT_CORE_DOCUMENTATION: "https://docs.euno.ai/sources/dbt-core",
    LOOKER_DOCUMENTATION: "https://docs.euno.ai/sources/looker-integration",
    TABLEAU_DOCUMENTATION: "https://docs.euno.ai/sources/tableau-integration",
    SNOWFLAKE_DOCUMENTATION: "https://docs.euno.ai/sources/snowflake-integration",
    THOUGHT_SPOT_DOCUMENTATION: "https://docs.euno.ai/sources/thoughtspot-integration",
    BIGQUERY_DOCUMENTATION: "https://docs.euno.ai/sources/bigquery-integration",
    OPTIMIZE_COMPLEX_QUERIES_DOCUMENTATION: "https://docs.euno.ai/guides/custom-properties/optimizing-search"
};