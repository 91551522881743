import { ArrowsRightLeftIcon, PlusIcon, HomeIcon } from "@heroicons/react/24/outline";
import { ArrowPathIcon, FolderIcon } from "@heroicons/react/24/solid";
import { useMemo, useState } from "react";
import { BranchIcon, EunoIcon, GithubIcon, MergeIcon } from "src/assets/images/icons/DelphiIcons";
import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import { DataSyncRunStatusLabel } from "src/features/dataModelSync/syncRuns/DataSyncRunStatusLabel";
import { useGetProjectQuery } from "src/services/projects/projects";
import { SyncJob } from "src/features/dataModelSync/types";
import { dateFormats, utcToLocal } from "src/infrastructure/dateUtilities";
import { useArchiveJobMutation, useGetJobsQuery, useGetRunsQuery, useGetTargetsQuery, useRunJobMutation } from "src/services/actions/actions";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { notify } from "src/components/Toaster";
import { extractErrorMessage } from "src/services/api";
import DropdownMenu from "src/components/DropdownMenu/DropdownMenu";
import Modal from "src/components/Modal/Modal";
import { useNavigate } from "react-router-dom";
import { useGetGithubIntegrationsQuery, useGetGithubRepositoriesQuery } from "src/services/integrations/integrations";

export const DataModelSyncJobs = () => {
    const navigate = useNavigate();
    const createSyncJob = () => {
        navigate('/create-sync-job');
    };
    const accountId = useSelector(selectActiveAccountId);
    const getJobs = useGetJobsQuery({ accountId });
    const jobs = getJobs.data || [];

    return (
        <div className="m-4">
            <div className="flex justify-between items-center">
                <div className="text-text-primary font-semibold">{jobs.length} sync jobs</div>
                <Button icon={<PlusIcon width="14" height="14" />} text="Create sync job" type={ButtonTypes.primary} onClick={createSyncJob} />
            </div>
            <div className="flex flex-wrap gap-4 mt-4">
                {
                    jobs.map(job => <SyncJobContainer job={job} key={job.id} />)
                }
            </div>
        </div>
    );
};

const SyncJobContainer = ({ job }: { job: SyncJob }) => {
    const getProject = useGetProjectQuery(job.configuration.eunoProjectId || 0, { skip: !job.configuration.eunoProjectId });
    const [runSyncJob, { isLoading }] = useRunJobMutation();
    const accountId = useSelector(selectActiveAccountId);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const navigate = useNavigate();
    const githubIntegrationsQuery = useGetGithubIntegrationsQuery({ accountId });
    const githubAccountId = useMemo(() => githubIntegrationsQuery.data?.[0]?.githubAccountId || 0, [githubIntegrationsQuery.data]);
    const repositoriesQuery = useGetGithubRepositoriesQuery({ accountId, githubAccountId }, { skip: !githubAccountId });
    const repositories = useMemo(() => repositoriesQuery.data || [], [repositoriesQuery.data]);
    const targets = useGetTargetsQuery({ accountId });
    const selectedTarget = targets.data?.find(target => target.id === job.targetId);
    const selectedRepository = repositories.find(repository => repository.cloneUrl === selectedTarget?.configuration?.targetRepositoryUrl);
    const getJobRuns = useGetRunsQuery({ accountId, jobId: job.id, page: 1, pageSize: 1 });
    const lastRun = getJobRuns.data?.items?.[0] || null;

    const editSyncJob = () => {
        navigate(`/edit-sync-job/${job.id}`);
    };

    const runNow = async () => {
        try {
            await runSyncJob({ jobId: job.id, accountId }).unwrap();
            notify('Sync job started successfully', 'success');
        }
        catch (e) {
            notify(`Error starting sync job: ${extractErrorMessage(e).message}`, 'error');
        }
    };

    const onRepositoryClick = () => {
        const repositoryUrl = selectedRepository?.cloneUrl?.replace('.git', '');
        const branchName = job.configuration.targetBranch;
        const url = `${repositoryUrl}/tree/${branchName}`;
        if (repositoryUrl) {
            window.open(url, '_blank');
        }
    };

    return (
        <div className="border border-border shadow rounded p-4 bg-white min-w-[350px] flex flex-col h-[260px] max-w-[350px]">
            <div className="flex gap-2 items-center">
                <div className="w-10 h-10 rounded bg-lilac-100 text-lilac-800 flex items-center justify-center">
                    <ArrowsRightLeftIcon width="22" height="22" />
                </div>
                <div className="flex flex-col gap-0.5">
                    <div className="text-text-primary text-base flex items-center gap-2">
                        {job.name}
                        {
                            lastRun && (
                                <DataSyncRunStatusLabel className="text-xs" status={lastRun.state} />
                            )
                        }
                    </div>
                    {
                        getProject.data && (
                            <div className="text-secondary text-sm flex gap-0.5 items-center">
                                <EunoIcon width="12" height="12" className="text-slate-400" />
                                {getProject.data.name}
                            </div>
                        )
                    }
                </div>
                <div className="ml-auto text-slate-400 cursor-pointer hover:text-slate-500">
                    <DropdownMenu items={[
                        {
                            name: 'Edit configuration',
                            onClick: editSyncJob
                        },
                        {
                            name: 'View job runs',
                            onClick: () => navigate(`/data-model-sync?tab=runs&jobId=${job.id}`),
                        },
                        {
                            name: 'Delete sync job',
                            onClick: () => setShowDeleteModal(true),
                            className: '!text-danger',
                            withSeparator: true
                        }
                    ]} />
                </div>
            </div>
            <div className="my-4 rounded bg-slate-50 border border-border p-2 flex flex-col gap-1.5 text-sm flex-1">
                {
                    job.targetId && (
                        <>
                            <div className="flex gap-2 items-center"><GithubIcon className="text-secondary" width="14" height="14" /><div className="cursor-pointer text-slate-500 hover:text-slate-600" onClick={onRepositoryClick}>{selectedRepository?.name}</div></div>
                            <div className="text-slate-500 flex gap-2 items-center"><BranchIcon className="text-secondary" width="14" height="14" /> {job.configuration.targetBranch}</div>
                            <div className="text-slate-500 flex gap-2 items-center"><FolderIcon className="text-secondary" width="14" height="14" /> {job.configuration.targetDirectory}</div>
                            <div className="text-slate-500 flex gap-2 items-center">
                                <MergeIcon className="text-secondary" width="14" height="14" />
                                {job.configuration.pushType === 'commit' && 'Commit directly to branch'}
                                {job.configuration.pushType === 'pull_request' && 'Create a pull request'}
                            </div>
                        </>
                    )
                }
                {
                    !job.targetId && (
                        <div className="text-slate-500 flex gap-2 items-center"><HomeIcon className="text-secondary" width="14" height="14" /> Runs locally</div>
                    )
                }
                <div className="text-slate-500 flex gap-2 items-center">
                    <ArrowPathIcon className="text-secondary" width="14" height="14" />
                    {
                        getModelSyncLabel(job)
                    }
                </div>
            </div>
            <div className="flex justify-between items-center mt-auto">
                <div className="text-tertiary text-sm"> {lastRun && (<>Last run on {utcToLocal(lastRun.createdAt, dateFormats.monthsDaysHoursAndMinutes)}</>)}</div>
                <Button isLoading={isLoading} text="Run now" type={ButtonTypes.secondary} icon={<ArrowPathIcon width="12" height="12" className="mr-0.5" />} onClick={runNow} />
            </div>
            <DeleteSyncJobModal isOpen={showDeleteModal} onClose={() => setShowDeleteModal(false)} job={job} />
        </div>
    );
};

const getModelSyncLabel = (job: SyncJob) => {
    let label = '';
    if (job.configuration.automaticallySyncDataModel) {
        label = 'Automatically';
    }
    else {
        label = 'Manually';
    }
    switch (job.configuration.modelSyncType) {
        case 'all':
            label += ' sync all models';
            break;
        case 'selected_models':
            label += ' sync specific models';
            break;
        case 'selected_tags':
            label += ' sync specific model tags';
            break;
    }
    return label;
};

const DeleteSyncJobModal = ({ isOpen, onClose, job }: { isOpen: boolean, onClose: () => void, job: SyncJob }) => {
    const [archiveJobMutation, { isLoading }] = useArchiveJobMutation();
    const accountId = useSelector(selectActiveAccountId);
    const deleteJob = async () => {
        try {
            await archiveJobMutation({ jobId: job.id, accountId }).unwrap();
            onClose();
            notify('Sync job deleted successfully', 'success');
        }
        catch (e) {
            notify(`Error deleting sync job: ${extractErrorMessage(e).message}`, 'error');
        }
    };
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Delete sync job"
            buttons={[
                {
                    type: ButtonTypes.secondary,
                    text: 'Cancel',
                    onClick: onClose
                },
                {
                    type: ButtonTypes.danger,
                    text: 'Delete',
                    onClick: deleteJob,
                    isLoading
                }
            ]}
        >
            <div className="text-text-primary">Are you sure you want to delete the sync job <span className="font-semibold">{job.name}</span>?</div>
        </Modal>
    );
};