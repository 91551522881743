import api from '../api';
import { CustomProperty } from '../nodes/types';

export const customPropertiesApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCustomProperties: build.query<CustomProperty[], { accountId: number }>({
            query: ({ accountId }) => `accounts/${accountId}/custom_properties?page_size=1000`,
            providesTags: ['CustomProperties']
        }),
        createCustomProperty: build.mutation<CustomProperty, { accountId: number, property: Omit<CustomProperty, 'id' | 'last_updated_at' | 'last_updated_by' | 'created_by' | 'created_at' | 'last_calculation_timeout'> }>({
            query: ({ accountId, property }) => ({
                url: `accounts/${accountId}/custom_properties`,
                method: 'POST',
                body: property
            }),
            invalidatesTags: ['CustomProperties']
        }),
        updateCustomProperty: build.mutation<CustomProperty, { accountId: number, propertyId: number, property: Partial<CustomProperty> }>({
            query: ({ accountId, propertyId, property }) => ({
                url: `accounts/${accountId}/custom_properties/${propertyId}`,
                method: 'PATCH',
                body: property
            }),
            invalidatesTags: ['CustomProperties']
        }),
        deleteCustomProperty: build.mutation<void, { accountId: number, propertyId: number }>({
            query: ({ accountId, propertyId }) => ({
                url: `accounts/${accountId}/custom_properties`,
                method: 'DELETE',
                params: { custom_property_id: propertyId }
            }),
            invalidatesTags: ['CustomProperties']
        }),
        setCustomPropertyValue: build.mutation<null, { 
            accountId: number, 
            nodeId: string, 
            propertyId: number, 
            value: string | boolean | number | string[] | null
        }>({
            query: ({ accountId, nodeId, propertyId, value }) => ({
                url: `accounts/${accountId}/custom_properties/${propertyId}/values`,
                method: 'POST',
                body: { 
                    uris: [nodeId],
                    value: value
                }
            }),
            transformResponse: () => null,
            invalidatesTags: ['Nodes']
        }),
    })
});

export const {
    useGetCustomPropertiesQuery,
    useCreateCustomPropertyMutation,
    useUpdateCustomPropertyMutation,
    useDeleteCustomPropertyMutation,
    useSetCustomPropertyValueMutation
} = customPropertiesApi; 