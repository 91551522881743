import { useState, useEffect, useMemo } from "react";
import Input from "./form/Input";
import Button from "./button/Button";
import { ButtonTypes } from "./button/types";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { Alert } from "./Alert";
import { QuestionMarkCircleIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { useLazyCheckEQLQuery } from "src/services/accounts/accounts";
import { debounce } from "src/utils/debounce";
import { Text2EQLModal } from "./Text2EQLModal";
 
type EQLInputProps = {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    allowCustomProperties?: boolean;
};

type ValidationState = {
    type: 'success' | 'error';
    message: string;
} | null;

type EQLErrorResponse = {
    data?: {
        detail?: {
            error: string;
            line?: number;
            column?: number;
        };
    };
};

export const EQLInput = ({ 
    value, 
    onChange, 
    placeholder = "Enter EQL expression",
    allowCustomProperties = true 
}: EQLInputProps) => {
    const accountId = useSelector(selectActiveAccountId);
    const [validation, setValidation] = useState<ValidationState>(null);
    const [checkEQL, { isLoading: isChecking }] = useLazyCheckEQLQuery();
    const [showHelpModal, setShowHelpModal] = useState(false);

    const verifyEQL = async () => {
        if (!value.trim()) {
            setValidation(null);
            return;
        }

        setValidation(null);
        
        try {
            await checkEQL({
                accountId,
                eql: value,
                allowCustomProperties
            }).unwrap();
            
            setValidation({
                type: 'success',
                message: 'EQL expression is valid'
            });
        } catch (err: unknown) {
            const error = err as EQLErrorResponse;
            if (error?.data?.detail) {
                setValidation({
                    type: 'error',
                    message: `${error.data.detail.error}${error.data.detail.line ? ` (Line ${error.data.detail.line}, Column ${error.data.detail.column})` : ''}`
                });
            } else {
                setValidation({
                    type: 'error',
                    message: 'Failed to check EQL expression'
                });
            }
        }
    };

    const verifyEQLDebounced = useMemo(
        () => debounce((value: string) => {
            if (!value.trim()) {
                setValidation(null);
                return;
            }

            setValidation(null);
            
            checkEQL({
                accountId,
                eql: value,
                allowCustomProperties
            }).unwrap()
            .then(() => {
                setValidation({
                    type: 'success',
                    message: 'EQL expression is valid'
                });
            })
            .catch((err: unknown) => {
                const error = err as EQLErrorResponse;
                if (error?.data?.detail) {
                    setValidation({
                        type: 'error',
                        message: `${error.data.detail.error}${error.data.detail.line ? ` (Line ${error.data.detail.line}, Column ${error.data.detail.column})` : ''}`
                    });
                } else {
                    setValidation({
                        type: 'error',
                        message: 'Failed to check EQL expression'
                    });
                }
            });
        }, 1500),
        [accountId, allowCustomProperties, checkEQL]
    );

    useEffect(() => {
        verifyEQLDebounced(value);
        return () => {
            verifyEQLDebounced.cancel();
        };
    }, [value, verifyEQLDebounced]);

    return (
        <>
            <div className="flex flex-col gap-2">
                <div className="flex items-start gap-2">
                    <Input
                        type="text"
                        value={value}
                        onInputChange={(e) => {
                            onChange(e.target.value);
                            setValidation(null);
                        }}
                        placeholder={placeholder}
                        className="flex-1"
                    />
                    <div className="flex items-center gap-2 mt-2">
                        <Button
                            type={ButtonTypes.secondary}
                            icon={<CheckCircleIcon width={20} height={20} />}
                            onClick={verifyEQL}
                            isLoading={isChecking}
                            className="!p-1 min-w-0"
                            text=""
                        />
                        <QuestionMarkCircleIcon 
                            width={20} 
                            height={20} 
                            className="text-slate-400 cursor-pointer hover:text-slate-600"
                            onClick={() => setShowHelpModal(true)}
                        />
                    </div>
                </div>
                
                {validation && (
                    <Alert
                        type={validation.type}
                        text={validation.message}
                    />
                )}
            </div>

            <Text2EQLModal
                isOpen={showHelpModal}
                onClose={() => setShowHelpModal(false)}
                onGenerated={(eql) => {
                    onChange(eql);
                    setValidation(null);
                }}
            />
        </>
    );
}; 