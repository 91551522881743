import { useSelector } from "react-redux";
import { IExpandedNode } from "src/features/models/discover/INode";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useSetCustomPropertyValueMutation } from 'src/services/nodes/nodes';
import { CustomProperty, CustomPropertyType } from "src/services/nodes/types";
import { InformationCircleIcon, PencilIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import { SidePaneTabDefinition } from "../types";
import DropdownMenu from "src/components/DropdownMenu/DropdownMenu";
import { useState } from "react";
import { Toggle } from "src/components/Toggle";
import { PropertyValue } from "./renderPropertyValue";

type ResourceSidepaneCustomTabProps = {
    tabDefinition: SidePaneTabDefinition;
    resource: IExpandedNode;
}

const formatDateForInput = (dateStr: string) => {
    const date = new Date(dateStr);
    return `${date.getFullYear()}-${
        String(date.getMonth() + 1).padStart(2, '0')}-${
        String(date.getDate()).padStart(2, '0')}T${
        String(date.getHours()).padStart(2, '0')}:${
        String(date.getMinutes()).padStart(2, '0')}:${
        String(date.getSeconds()).padStart(2, '0')}`;
};

export const ResourceSidepaneCustomTab = ({ tabDefinition, resource }: ResourceSidepaneCustomTabProps) => {
    const [editingNumericField, setEditingNumericField] = useState<string | null>(null);
    const properties = [...(tabDefinition.customProperties || [])];
    const accountId = useSelector(selectActiveAccountId);
    const [setCustomPropertyValue] = useSetCustomPropertyValueMutation();

    const handleNumericEdit = (property: CustomProperty, value: string) => {
        const numValue = parseFloat(value);
        if (!isNaN(numValue)) {
            setCustomPropertyValue({ accountId, nodeId: resource.id, propertyId: property.id, value: numValue });
        }
    };

    const isManualProperty = (property: CustomProperty) => {
        return property.content.type.startsWith('manual_');
    };

    const getEditOptions = (property: CustomProperty) => {
        const unsetOption = [{
            name: 'Unset value',
            onClick: () => setCustomPropertyValue({
                accountId,
                nodeId: resource.id,
                propertyId: property.id,
                value: null
            })
        }];

        switch (property.content.type) {
            case CustomPropertyType.manual_multi_select:
                return [
                    ...unsetOption,
                    ...((property.content.content as string[]) || []).map(option => ({
                        name: option,
                        onClick: () => {
                            setCustomPropertyValue({ accountId, nodeId: resource.id, propertyId: property.id, value: option });
                        }
                    }))
                ];
            case CustomPropertyType.manual_single_select:
                return [
                    ...unsetOption,
                    ...((property.content.content as string[]) || []).map(option => ({
                        name: option,
                        onClick: () => setCustomPropertyValue({
                            accountId,
                            nodeId: resource.id,
                            propertyId: property.id,
                            value: option
                        })
                    }))
                ];
            default:
                return [];
        }
    };

    const renderEditControl = (property: CustomProperty) => {
        switch (property.content.type) {
            case CustomPropertyType.manual_numeric_field:
                return editingNumericField === property.name ? (
                    <input
                        type="number"
                        className="w-24 px-2 py-1 border border-border rounded"
                        defaultValue={resource.customProperties[property.name] as string}
                        onBlur={(e) => handleNumericEdit(property, e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleNumericEdit(property, e.currentTarget.value);
                            } else if (e.key === 'Escape') {
                                setEditingNumericField(null);
                            }
                        }}
                        autoFocus
                    />
                ) : (
                    <PencilIcon 
                        width="16" 
                        height="16" 
                        className="text-slate-400 hover:text-slate-600 cursor-pointer" 
                        onClick={() => setEditingNumericField(property.name)}
                    />
                );
            case CustomPropertyType.manual_boolean_field:
                return (
                    <Toggle 
                        value={!!resource.customProperties[property.name]} 
                        onChange={() => setCustomPropertyValue({ 
                            accountId, 
                            nodeId: resource.id, 
                            propertyId: property.id, 
                            value: !resource.customProperties[property.name] 
                        })} 
                    />
                );
            case CustomPropertyType.manual_single_select:
                return (
                    <DropdownMenu
                        items={((property.content.content as string[]) || []).map(option => ({
                            name: option,
                            onClick: () => setCustomPropertyValue({
                                accountId,
                                nodeId: resource.id,
                                propertyId: property.id,
                                value: option
                            })
                        }))}
                    >
                        <PencilIcon width="16" height="16" className="text-slate-400 hover:text-slate-600 cursor-pointer" />
                    </DropdownMenu>
                );
            case CustomPropertyType.manual_multi_select:
                return <div/>;
            case CustomPropertyType.manual_datetime_field:
                return editingNumericField === property.name ? (
                    <input
                        type="datetime-local"
                        className="px-2 py-1 border border-border rounded"
                        defaultValue={resource.customProperties[property.name] ? 
                            formatDateForInput(resource.customProperties[property.name] as string) : 
                            ''
                        }
                        onBlur={(e) => {
                            if (e.target.value) {
                                const value = e.target.value.includes(':00') ? 
                                    e.target.value : 
                                    `${e.target.value}:00`;
                                
                                setCustomPropertyValue({
                                    accountId,
                                    nodeId: resource.id,
                                    propertyId: property.id,
                                    value
                                });
                            }
                            setEditingNumericField(null);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && e.currentTarget.value) {
                                const value = e.currentTarget.value.includes(':00') ? 
                                    e.currentTarget.value : 
                                    `${e.currentTarget.value}:00`;

                                setCustomPropertyValue({
                                    accountId,
                                    nodeId: resource.id,
                                    propertyId: property.id,
                                    value
                                });
                                setEditingNumericField(null);
                            } else if (e.key === 'Escape') {
                                setEditingNumericField(null);
                            }
                        }}
                        autoFocus
                    />
                ) : (
                    <PencilIcon 
                        width="16" 
                        height="16" 
                        className="text-slate-400 hover:text-slate-600 cursor-pointer" 
                        onClick={() => setEditingNumericField(property.name)}
                    />
                );
            default:
                return (
                    <DropdownMenu items={getEditOptions(property)}>
                        <PencilIcon width="16" height="16" className="text-slate-400 hover:text-slate-600 cursor-pointer" />
                    </DropdownMenu>
                );
        }
    };

    return (
        <div className="bg-surface-light flex-1">
            <div className="p-4 flex-1 max-h-[80vh] overflow-y-auto">
                <div className="flex flex-col gap-2">
                    {properties.map((property) => (
                        <div key={property.id} className="flex items-center justify-between p-4 border border-border rounded-lg">
                            <div className="flex items-center gap-2">
                                <span className="font-semibold">
                                    {property.metadata.display_name || property.name}
                                </span>
                                {(property.metadata.description || property.name !== property.metadata.display_name) && (
                                    <>
                                        <InformationCircleIcon 
                                            width="16" 
                                            height="16" 
                                            className="text-slate-400" 
                                            id={`tooltip-${property.id}`} 
                                        />
                                        <Tooltip anchorSelect={`#tooltip-${property.id}`}>
                                            {property.metadata.description && (
                                                <>
                                                    {property.metadata.description}
                                                    <br/>
                                                </>
                                            )}
                                            {property.name !== property.metadata.display_name && (
                                                <>EQL name: {property.name}</>
                                            )}
                                        </Tooltip>
                                    </>
                                )}
                            </div>
                            <div className="flex items-center gap-4 overflow-visible">
                                <div className="text-slate-500 overflow-visible">
                                    <PropertyValue
                                        property={property}
                                        value={resource.customProperties[property.name] as string | string[] | null | undefined}
                                        accountId={accountId}
                                        nodeId={resource.id}
                                        setCustomPropertyValue={setCustomPropertyValue}
                                        isManualProperty={isManualProperty}
                                    />
                                </div>
                                {isManualProperty(property) &&
                                 (
                                    <div className="flex-shrink-0">
                                        {renderEditControl(property)}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}; 