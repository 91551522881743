import { IExpandedNode } from "../INode";
import { CustomProperty } from "src/services/nodes/types";

export enum BuiltInResourceTab {
    Overview = 'Overview',
    Schema = 'Schema',
    Contents = 'Contents',
    UsedFields = 'Used fields',
    Usage = 'Usage',
    Relationships = 'Relationships',
    About = 'About',
}
export type ResourceSidepaneSelectorProps = {
    resource: IExpandedNode;
    tabDefinition: SidePaneTabDefinition;
    activeTab: string;
    setActiveTab: (tab: string) => void;
}

export type SidePaneTabDefinition = {
    icon: React.ReactNode;
    label: string;
    isBuiltIn: boolean;
    name: string;
    builtInTab?: BuiltInResourceTab;
    customProperties?: CustomProperty[];
}