import { useEffect, useRef, useState } from "react";
import { UpdateDataModelStateProps } from "../types";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useLazyGetGenAIResponseQuery } from "src/services/nodes/nodes";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { notify } from "src/components/Toaster";

interface GenAIEditorProps {
    updateDataModelState: (props: UpdateDataModelStateProps) => void;
    setEqlInEdit: (eql: string) => void;
    isLoading?: boolean;
    text: string;
    setText: (text: string) => void;
}

export const GenAIEditor = ({ updateDataModelState, setEqlInEdit, text, setText }: GenAIEditorProps) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const accountId = useSelector(selectActiveAccountId);
    const [isLoading, setIsLoading] = useState(false);
    const [getGenAIResponse] = useLazyGetGenAIResponseQuery();

    const executeQuery = async (query: string) => {
        try {
            setIsLoading(true);
            const response = await getGenAIResponse({ accountId, prompt: query }).unwrap();
            setEqlInEdit(response.eql);
            updateDataModelState({
                eql: response.eql,
            });
            setIsLoading(false);
        } catch (error) {
            notify('Failed to generate EQL query', 'error');
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
            e.preventDefault();
            if (text) {
                executeQuery(text);
            }
        }
    };

    useEffect(() => {
        // Focus the input on mount
        inputRef.current?.focus();
    }, []);

    return (
        <div className="flex-1">
            <div className="flex items-center gap-2 bg-white rounded px-2">
                <textarea
                    ref={inputRef}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    disabled={isLoading}
                    className={`flex-1 resize-none outline-none py-2 h-[32px] overflow-hidden ${isLoading ? 'text-slate-400' : ''}`}
                    placeholder="What are all the dashboards that are downstream of the model 'users'?"
                    onKeyDown={handleKeyDown}
                    rows={1}
                />
                <a
                    href="https://docs.delphi.com/genai"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-slate-400 hover:text-slate-600"
                >
                    <QuestionMarkCircleIcon className="w-5 h-5" />
                </a>
                <button
                    onClick={() => text && executeQuery(text)}
                    disabled={isLoading}
                    className="text-white hover:text-slate-50 p-1 rounded-lg bg-lilac-950 disabled:opacity-50"
                >
                    <MagnifyingGlassIcon className="w-4 h-4" />
                </button>
            </div>
            {text && (
                <div className="text-lilac-400 w-fit ml-auto mr-2 text-sm mt-1">
                    Press <span className="font-semibold text-lilac-600">{navigator.platform.includes('Mac') ? 'Cmd + Enter' : 'Ctrl + Enter'}</span> to run query
                </div>
            )}
        </div>
    );
}; 