export type LuzmoDashboard = {
    name: string;
    id: string;
}

export type LuzmoEmbedCredentials = {
    token_id: string | null;
    token_secret: string | null;
    dashboards: LuzmoDashboard[];
}

export enum LuzmoCustomEventName {
    showDetails = 'show_details',
    showInDataModel = 'show_in_data_model',
    showPotentialConflicts = 'show_potential_conflicts',
}

export type LuzmoCustomEventColumn = {
    label: string;
    value: string | number;
}
