import { CircleStackIcon } from "@heroicons/react/24/outline";
import dateFormat from "dateformat";
import { DbtCloudIcon, EunoIcon, GithubIcon, LookerIcon } from "src/assets/images/icons/DelphiIcons";
import { IExpandedNode } from "src/features/models/discover/INode";
import { dateFormats } from "src/infrastructure/dateUtilities";

export const ResourceSidepaneAboutTab = ({ resource }: { resource: IExpandedNode }) => {
    const generalItems: Item[] = [];
    if (resource.nativeId) {
        generalItems.push({ label: 'Native id', value: resource.nativeId });
    }
    if (resource.sponsors) {
        // Convert array of strings to a single string
        const sponsorsValue = Array.isArray(resource.sponsors) ? resource.sponsors.join(', ') : resource.sponsors;
        generalItems.push({ label: 'Sponsors', value: sponsorsValue });
    }
    return (
        <div className="bg-surface-light h-full p-4 flex flex-col gap-3">
            <span className="text-secondary font-semibold">About this item</span>
            {
                generalItems.length > 0 && (
                    <div className="flex flex-col gap-1">
                        {
                            generalItems.map(item => <AboutItem key={item.label} {...item} />)
                        }
                    </div>
                )
            }
            <GithubItems resource={resource} />
            <DatabaseItems resource={resource} />
            <LookerItems resource={resource} />
            <DbtItems resource={resource} />
            <EunoItems resource={resource} />
        </div>
    );
};

const GithubItems = ({ resource }: { resource: IExpandedNode }) => {
    const items: Item[] = [];
    if (resource.repo) {
        items.push({ label: 'Repository', value: resource.repo });
    }
    if (resource.branch) {
        items.push({ label: 'Branch', value: resource.branch });
    }
    if (items.length === 0) {
        return null;
    }
    return (
        <div className="flex flex-col gap-1">
            <div className="flex gap-1 items-center font-semibold text-slate-700">
                <GithubIcon width="14" height="14" fill="black" />
                Git
            </div>
            {
                items.map(item => <AboutItem key={item.label} {...item} />)
            }
        </div>
    );
};

const DatabaseItems = ({ resource }: { resource: IExpandedNode }) => {
    const items: Item[] = [];
    if (resource.database) {
        items.push({ label: 'Database', value: resource.database });
    }
    if (resource.databaseSchema) {
        items.push({ label: 'Schema', value: resource.databaseSchema });
    }
    if (items.length === 0) {
        return null;
    }
    return (
        <div className="flex flex-col gap-1">
            <div className="flex gap-1 items-center font-semibold text-slate-700">
                <CircleStackIcon width="14" height="14" />
                Database
            </div>
            {
                items.map(item => <AboutItem key={item.label} {...item} />)
            }
        </div>
    );
};

const LookerItems = ({ resource }: { resource: IExpandedNode }) => {
    const items: Item[] = [];
    if (resource.lookerProject) {
        items.push({ label: 'Project', value: resource.lookerProject });
    }
    if (resource.lookerFolder) {
        items.push({ label: 'Folder', value: resource.lookerFolder });
    }
    if (resource.lookerModel) {
        items.push({ label: 'Model', value: resource.lookerModel });
    }
    if (resource.lookerConnectionName) {
        items.push({ label: 'Connection name', value: resource.lookerConnectionName });
    }
    if (resource.lookerRefinementChain.length > 0) {
        items.push({ label: 'Refinement chain', value: resource.lookerRefinementChain.join(' > ') });
    }
    if (items.length === 0) {
        return null;
    }
    return (
        <div className="flex flex-col gap-1">
            <div className="flex gap-1 items-center font-semibold text-slate-700">
                <LookerIcon width="14" height="14" />
                Looker
            </div>
            {
                items.map(item => <AboutItem key={item.label} {...item} />)
            }
        </div>
    );
};

const DbtItems = ({ resource }: { resource: IExpandedNode }) => {
    const items: Item[] = [];
    if (resource.dbtProjectName) {
        items.push({ label: 'Project', value: resource.dbtProjectName });
    }
    if (resource.package) {
        items.push({ label: 'Package', value: resource.package });
    }
    if (resource.dbtVersion) {
        items.push({ label: 'Version', value: resource.dbtVersion });
    }
    if (resource.hasCatalogEntry === false) {
        items.push({ label: 'Partial Schema', value: 'This resource is missing from the catalog.json. As a result, schema information is unavailable for this resource.' });
    }
    if (items.length === 0) {
        return null;
    }
    return (
        <div className="flex flex-col gap-1">
            <div className="flex gap-1 items-center font-semibold text-slate-700">
                <DbtCloudIcon color="#FF694A" width="14" height="14" />
                dbt
            </div>
            {
                items.map(item => <AboutItem key={item.label} {...item} />)
            }
        </div>
    );
};

const EunoItems = ({ resource }: { resource: IExpandedNode }) => {
    const items: Item[] = [];
    if (resource.firstSeen) {
        items.push({ label: 'First observed', value: dateFormat(resource.firstSeen, dateFormats.date) });
    }
    if (resource.lastSeen) {
        items.push({ label: 'Last observed', value: dateFormat(resource.lastSeen, dateFormats.date) });
    }
    if (items.length === 0) {
        return null;
    }
    return (
        <div className="flex flex-col gap-1">
            <div className="flex gap-1 items-center font-semibold text-slate-700">
                <EunoIcon width="14" height="14" />
                Euno
            </div>
            {
                items.map(item => <AboutItem key={item.label} {...item} />)
            }
        </div>
    );
};

const AboutItem = ({ label, value }: Item) => {
    return (
        <div className="flex gap-1">
            <span className="text-tertiary whitespace-nowrap">{label}</span>
            <span className="text-text-primary flex-wrap">{value}</span>
        </div>
    );
};

type Item = {
    label: string;
    value: string;
}
