type ChipProps = {
    children: React.ReactNode;
    onDelete?: () => void;
};

export const Chip = ({ children, onDelete }: ChipProps) => (
    <div className="border border-slate-100 text-tertiary rounded px-1 text-sm font-fira-code flex items-center">
        {children}
        {onDelete && (
            <button
                onClick={onDelete}
                className="ml-1 text-slate-400 hover:text-slate-600"
            >
                ×
            </button>
        )}
    </div>
); 